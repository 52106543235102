import { Locality } from '@appTypes/Text.types';
import { authenticatedBaseQuery } from '@redux/utils/authenticatedBaseQuery';
import { createApi } from '@reduxjs/toolkit/query/react';

const textApi = createApi({
  reducerPath: 'text',
  baseQuery: authenticatedBaseQuery('text'),
  endpoints: (builder) => ({
    getLocation: builder.query<
      Locality,
      { searchParam: string; language: string }
    >({
      query: ({ searchParam, language }) =>
        `/api/map/culture/${language}/input/${searchParam}`,
      transformResponse: (response: any) => response?.data as Locality,
    }),
  }),
});

export const { useGetLocationQuery, useLazyGetLocationQuery } = textApi;
export default textApi;
