import React, { Suspense } from 'react';
import { BrowserRouter, Routes as Switch, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '@redux/store';
import cn from 'classnames';
import SideBar from '@common/SideBar';
import { useInitApp } from '@common/utils/hooks';
import Header from '@common/Header';
import Loader from '@common/Loader';
import { useUILayout } from '@common/utils/UILayoutProvider';
import NPS from '@common/NPS';

import styles from './styles.module.scss';
import ErrorBoundary from './ErrorBoundary';
import ReleaseNotificationScreen from './ReleaseNotificationScreen';

const CampaignAccessDenied = React.lazy(
  () => import('@components/Campaigns/CampaignDetails/AccessDenied')
);
const Home = React.lazy(() => import('@components/Home'));
const Campaigns = React.lazy(() => import('@components/Campaigns'));
const CampaignDetails = React.lazy(
  () => import('@components/Campaigns/CampaignDetails')
);
const CreateCampaign = React.lazy(
  () => import('@components/Campaigns/CreateCampaign')
);
const ConversationTemplates = React.lazy(
  () => import('@components/ConversationTemplates')
);
const ConversationEditor = React.lazy(
  () => import('@components/ConversationTemplates/ConversationEditor')
);
const Reporting = React.lazy(() => import('@components/Reporting'));
const Users = React.lazy(() => import('@components/Users'));
const AccessDenied = React.lazy(() => import('@common/AccessDenied'));
const LegacyRoutes = React.lazy(() => import('@components/Legacy/routes'));

const LoaderComponent = <Loader fullHeight />;

const withSuspense = (Component) => (
  <Suspense fallback={LoaderComponent}>{Component}</Suspense>
);

const Routes = () => {
  const [isLoaded, userHasAccess] = useInitApp();
  const { isMessageBarOpen, isSidebarOpen } = useUILayout();

  const { apiError } = useSelector((store: RootState) => store.errors);

  if (!isLoaded) {
    return LoaderComponent;
  }

  if (apiError?.status === 403 || !userHasAccess) {
    return withSuspense(<AccessDenied />);
  }

  return (
    <BrowserRouter>
      <Header />
      <SideBar />
      <NPS />
      <div
        className={cn({
          [styles.messageBarOpen]: isMessageBarOpen,
          [styles.sideBarOpen]: isSidebarOpen,
        })}
      >
        <ErrorBoundary>
          <ReleaseNotificationScreen>
            <Switch>
              <Route path="/home" element={withSuspense(<Home />)} />
              <Route path="/campaigns" element={withSuspense(<Campaigns />)} />
              <Route
                path="/campaigns/forbidden"
                element={withSuspense(<CampaignAccessDenied />)}
              />
              <Route
                path="/campaigns/:campaignId/:tabId"
                element={withSuspense(<CampaignDetails />)}
              />
              <Route
                path="/campaigns/create/:type/:stepId"
                element={withSuspense(<CreateCampaign />)}
              />
              <Route
                path="conversationTemplates/:templateId/conversationEditor"
                element={withSuspense(<ConversationEditor />)}
              />
              <Route
                path="/conversationTemplates"
                element={withSuspense(<ConversationTemplates />)}
              />
              <Route path="/reporting" element={withSuspense(<Reporting />)} />
              <Route path="/userManagement" element={withSuspense(<Users />)} />
              <Route
                path="/forbidden"
                element={withSuspense(<AccessDenied />)}
              />
              {/*Redirect to legacy pages*/}
              <Route path="*" element={withSuspense(<LegacyRoutes />)} />
            </Switch>
          </ReleaseNotificationScreen>
        </ErrorBoundary>
      </div>
    </BrowserRouter>
  );
};

export default Routes;
