import { authenticatedBaseQuery } from '@redux/utils/authenticatedBaseQuery';
import { createApi } from '@reduxjs/toolkit/query/react';
import { CandidateOutreach } from '@appTypes/Communication.types';

const communicationApi = createApi({
  reducerPath: 'communication',
  baseQuery: authenticatedBaseQuery('comm'),
  endpoints: (builder) => ({
    getEmailBlocked: builder.query<boolean, string>({
      query: (email: string) => `/api/BlacklistEmail/isBlocked?email=${email}`,
    }),
    getPhoneBlocked: builder.query<boolean, string>({
      query: (phone: string) =>
        `/api/BlacklistPhone/isBlocked?phoneNumber=${phone}`,
    }),
    getCandidateOutreachData: builder.query<CandidateOutreach[], string>({
      query: (candidiateId: string) =>
        `/api/candidateCommunication/${candidiateId}/outreach-info`,
    }),
  }),
});

export const {
  useGetEmailBlockedQuery,
  useGetPhoneBlockedQuery,
  useGetCandidateOutreachDataQuery,
} = communicationApi;
export default communicationApi;
