import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import preval from 'preval.macro';

(i18n as any)
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    backend: {
      loadPath:
        '/locales/{{lng}}/{{ns}}.json?' +
        preval(`module.exports = new Date().getTime();`),
    },
    detection: {
      order: [
        'querystring',
        // 'localStorage', temporarily disabled
        'navigator',
      ],
    },
    load: 'currentOnly',
    ns: [
      'common',
      'layout',
      'dashboard',
      'campaign',
      'conversation',
      'users',
      'notificationTextResources',
      'dataSanitization',
      'conversationDesigner',
      'reporting',
    ],
    fallbackLng: {
      default: ['en'],
      'fr-CA': ['fr'],
      'fr-CH': ['fr'],
      'es-MX': ['es'],
      'es-CO': ['es'],
      'es-CL': ['es'],
      'es-PE': ['es'],
      'es-AR': ['es'],
    },
    debug: false,
    keySeparator: '.',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    react: {
      useSuspense: false,
    },
  });

export default i18n;
