import cn from 'classnames';
import styles from './styles.module.scss';

interface ICardProps {
  children: React.ReactNode;
  className?: string;
  [rest: string]: any;
}

const Card: React.FC<ICardProps> = ({ children, className, ...rest }) => {
  return (
    <div className={cn(styles.card, className)} {...rest}>
      {children}
    </div>
  );
};

export default Card;
