export const configs = [
  'CountrySupportsIntegration',
  'CountrySupportsPushNotification',
  'CountrySupportsTargetChannel',
  'CountrySupportsTextToApply',
  'CountrySupportsJobId',
  'CountrySupportsHybridCSVOption',
  'AdditionalHybridCsvValidations',
  'CountryDoesNotSupportFirstNameSMSValidation',
  'CountryDoesNotSupportStopSMSUrlValidation',
  'ReleaseSchedule',
  'CampaignSplitTolerance',
  'CountrySupportsWhatsApp',
];

export const featureFlags = [
  '.appconfig.featureflag/isFileUploadEnabled',
  '.appconfig.featureflag/isFunnelReportFilterByChannelEnabled',
];
