import { useLocation, useNavigate } from 'react-router-dom';
import cn from 'classnames';
import { Sidebar, SidebarButton } from '@adeccoux/tag-ds';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import GridOnIcon from '@mui/icons-material/GridOn';
import InsertChartOutlinedIcon from '@mui/icons-material/InsertChartOutlined';
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import BrushIcon from '@mui/icons-material/Brush';
import InsightsIcon from '@mui/icons-material/Insights';
import { useHasPermission } from '@common/utils/hooks';
import Permissions from '@common/utils/Permissions';
import { useUILayout } from '@common/utils/UILayoutProvider';

import styles from './styles.module.scss';

const SideBar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { hasPermission } = useHasPermission();
  const { isSidebarOpen, setSidebarOpen } = useUILayout();

  const defaultItemSelected = location.pathname?.split('/')[1];

  return (
    <div className="tag-ds">
      <Sidebar
        collapsed={!isSidebarOpen}
        className={cn(styles.sideBar, { [styles.open]: isSidebarOpen })}
        defaultItemSelected={defaultItemSelected}
        onClickSidebarItem={(item) => navigate(`/${item}`)}
        onCollapse={(isCollapsed: boolean) => setSidebarOpen(!isCollapsed)}
      >
        <SidebarButton icon={<HomeOutlinedIcon />} id="home" label="Home" />
        {hasPermission(Permissions.campaign.read) && (
          <SidebarButton
            icon={<GridOnIcon />}
            id="campaigns"
            label="Campaigns"
          />
        )}
        <SidebarButton
          icon={<InsertChartOutlinedIcon />}
          id="reporting"
          label="Reporting"
        />
        {hasPermission(Permissions.template.read) && (
          <SidebarButton
            icon={<ChatBubbleOutlineOutlinedIcon />}
            id="conversationTemplates"
            label="Conversation Templates"
          />
        )}
        {hasPermission(Permissions.user.read) && (
          <SidebarButton
            icon={<SupervisedUserCircleIcon />}
            id="userManagement"
            label="User Management"
          />
        )}
        {hasPermission(Permissions.sanitizer.access) && (
          <SidebarButton
            icon={<InsightsIcon />}
            id="sanitizer"
            label="Data Sanitizer"
          />
        )}
        {hasPermission(Permissions.conversationDesigner.access) && (
          <SidebarButton
            icon={<BrushIcon />}
            id="conversationDesigner"
            label="Conversation Designer"
          />
        )}
      </Sidebar>
    </div>
  );
};

export default SideBar;
