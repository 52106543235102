export const Permissions = {
  application: {
    administrate: 'administrate-application',
    login: 'login-application',
  },
  conversationDesigner: {
    advanced: 'advanced-conversationDesigner',
    access: 'access-conversationDesigner',
  },
  exportReport: {
    access: 'access-exportReport',
  },
  integrationCampaign: {
    create: 'create-integrationCampaign',
  },
  brand: {
    create: 'create-brand',
    read: 'read-brand',
  },
  country: {
    create: 'create-country',
    read: 'read-country',
  },
  branchOffice: {
    create: 'create-branchOffice',
    read: 'read-branchOffice',
  },
  campaign: {
    create: 'create-campaign',
    deleteClosed: 'deleteClosed-campaign',
    deleteDraft: 'deleteDraft-campaign',
    editConversation: 'editConversation-campaign',
    launch: 'launch-campaign',
    read: 'read-campaign',
    update: 'update-campaign',
    stopIntegrationCampaign: 'stopIntegration-campaign',
  },
  messagingTemplate: {
    create: 'create-messagingtemplate',
    delete: 'delete-messagingtemplate',
    read: 'read-messagingtemplate',
    update: 'update-messagingtemplate',
  },
  rgpd: {
    deleteData: 'deleteData-rgpd',
    extractData: 'extractData-rgpd',
    searchCandidate: 'searchCandidate-rgpd',
  },
  role: {
    read: 'read-role',
  },
  sanitizer: {
    access: 'access-sanitizer',
  },
  template: {
    create: 'create-template',
    delete: 'delete-template',
    read: 'read-template',
    update: 'update-template',
  },
  user: {
    create: 'create-user',
    delete: 'delete-user',
    read: 'read-user',
    update: 'update-user',
  },
  jobid: {
    access: 'access-jobid',
  },
};

export default Permissions;