import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { Grid, Row, Col } from '@common/Grid';
import { useUILayout } from '@common/utils/UILayoutProvider';
import { ReleaseEventType } from '@common/NotificationProvider';
import { useGetNotificationsQuery } from '@redux/api/notificationApiSlice';
import { resolveTextResource } from '@common/utils';
import { useGetPermissionsByUserIdQuery } from '@redux/api/securityApiSlice';
import { useGetTokenQuery } from '@redux/api/portalApiSlice';

import styles from './App.module.scss';

const ReleaseNotificationScreen = ({ children }) => {
  const { t } = useTranslation('notificationTextResources');
  const { isMessageBarOpen, isSidebarOpen } = useUILayout();
  const { data: notifications } = useGetNotificationsQuery();
  const { data: tokenData } = useGetTokenQuery();

  const { data: permissions } = useGetPermissionsByUserIdQuery(tokenData?.oid, {
    skip: !tokenData?.oid,
  });

  const releaseNotification = notifications?.find(
    (item) => item?.data?.status === ReleaseEventType.DeploymentStarted
  );

  if (
    releaseNotification &&
    !permissions.find((permission) => permission.roleName === 'Global Admin')
  ) {
    return (
      <div
        className={cn(styles.releaseNotification, {
          [styles.messageBarOpen]: isMessageBarOpen,
          [styles.sideBarOpen]: isSidebarOpen,
        })}
      >
        <Grid>
          <Row marginBottom={16}>
            <Col colSpan={4}>
              <div className={styles.bannerImage} />
            </Col>
          </Row>
          <Row marginBottom={16}>
            <h2>{t('releaseTitle')}</h2>
          </Row>
          <Row marginBottom={16}>
            <div className={styles.subtitle}>
              {resolveTextResource(t, releaseNotification.title)}
            </div>
          </Row>
        </Grid>
      </div>
    );
  }

  return children;
};

export default ReleaseNotificationScreen;
