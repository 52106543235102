import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const templateSelectionSlice = createSlice({
  name: 'templateSelection',
  initialState: {
    formState: { selectedTemplate: null },
  },
  reducers: {
    setFormState: (state, action: PayloadAction<any>) => {
      state.formState = action.payload;
    },
    clearTemplateSelectionState: (state) => {
      state.formState = { selectedTemplate: null };
    },
  },
});

export const { setFormState, clearTemplateSelectionState } =
  templateSelectionSlice.actions;
export default templateSelectionSlice.reducer;
