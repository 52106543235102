import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const CURRENCY_SELECTED_KEY = 'currency';
const selectedCurrency = localStorage.getItem(CURRENCY_SELECTED_KEY);

const mainSlice = createSlice({
  name: 'mainStore',
  initialState: {
    selectedCurrency: selectedCurrency || 'USD',
    campaignsListFilters: {
      createdByMe : false
    },
    campaignsListSort: { isDescending: true, column: "created" },
  } as any,
  reducers: {
    selectedCurrencyChanged: (state, action: PayloadAction<string>) => {
      state.selectedCurrency = action?.payload;
      localStorage.setItem(CURRENCY_SELECTED_KEY, `"${action?.payload}"`);
    },
    setCampaignListFilters: (state, action: PayloadAction<any>) => {
      state.campaignsListFilters = action.payload;
    },
    setCampaignListSort: (state, action: PayloadAction<any>) => {
      state.campaignsListSort = action.payload;
    },
  },
});

export const {
  selectedCurrencyChanged,
  setCampaignListFilters,
  setCampaignListSort,
} = mainSlice.actions;

export default mainSlice.reducer;
