import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import cn from 'classnames';
import TextArea from '@common/formik/TextArea';
import Select from '@common/formik/Select';
import Grid, { Col, Row } from '@common/Grid';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import tagStyles from '@common/assets/styles/main.scss';
import { useGetTokenQuery } from '@redux/api/portalApiSlice';
import { useGetPermissionsByUserIdQuery } from '@redux/api/securityApiSlice';
import {
  useSendRecruiterNPSMutation,
  useShowNPSSurveyQuery,
} from '@redux/api/conversationApiSlice';

import styles from './styles.module.scss';
import { Steps, Options } from './constants';
import { validationSchema } from './validations';

const NPS = () => {
  const { t } = useTranslation('common');
  const [isOpen, setOpen] = useState(false);
  const [currentStep, setCurrentStep] = useState(Steps.RATING);
  const [selectedRating, setSelectedRating] = useState(null);

  const { data: token } = useGetTokenQuery();
  const { data: permissions } = useGetPermissionsByUserIdQuery(token?.oid, {
    skip: !token?.oid,
  });
  const { data: showNPSSurvey } = useShowNPSSurveyQuery(token?.oid, {
    skip: !token?.oid,
  });

  const [sendRecruiterNPS] = useSendRecruiterNPSMutation();

  const hasPermission = permissions?.some(
    (permission) =>
      permission?.roleName === 'Country Admin' ||
      permission?.roleName === 'Manager' ||
      permission?.roleName === 'Recruiter (Read-Only)' ||
      permission?.roleName === 'Recruiter'
  );

  useEffect(() => {
    if (showNPSSurvey?.showSurvey && hasPermission) {
      setOpen(true);
    }
  }, [showNPSSurvey]);

  const onClose = () => {
    setOpen(false);
    sendRecruiterNPS({
      userId: token?.oid,
      body: {
        npsRating: null,
        description: null,
        category: null,
        dateCreated: new Date().toISOString(),
        hasSkipped: true,
      },
    });
  };

  const onSubmit = (values) => {
    sendRecruiterNPS({
      userId: token?.oid,
      body: {
        npsRating: selectedRating,
        description: values?.comments,
        category: values?.category,
        dateCreated: new Date().toISOString(),
        hasSkipped: false,
      },
    });
    setOpen(false);
  };


  if (!isOpen) {
    return null;
  }

  if (currentStep === Steps.RATING) {
    return (
      <div className={styles.modalWrapper}>
        <div
          className={cn(
            tagStyles.tagDs,
            tagStyles.modal,
            tagStyles.docs,
            styles.modal
          )}
        >
          {showNPSSurvey.canSkip && (
            <button
              id="close"
              type="button"
              className={tagStyles.modalCloseButton}
              onClick={onClose}
            >
              <CloseOutlinedIcon />
            </button>
          )}
          <Grid>
            <Row marginBottom={24}>
              <h4 className={tagStyles.modalHeader}>{t('nps.title')}</h4>
            </Row>
            <div className={tagStyles.modalBody}>
              <Row marginBottom={12}>
                <div className={styles.subtitle}>{t('nps.subtitle')}</div>
              </Row>
              <Row marginBottom={24}>
                <div className={tagStyles.separator} />
              </Row>
              <Row marginBottom={24}>{t('nps.ratingTitle')}</Row>
              <Row marginBottom={24}>
                <div className={styles.container}>
                  <div className={styles.buttonBar}>
                    {[...Array(11).keys()]?.map((item) => (
                      <button
                        key={item}
                        className={cn(tagStyles.upperMenuIcon, {
                          [styles.selected]: item === selectedRating,
                        })}
                        onClick={() => setSelectedRating(item)}
                      >
                        {item}
                      </button>
                    ))}
                  </div>
                  <div className={styles.helper}>
                    <div>{t('nps.notLikely')}</div>
                    <div>{t('nps.veryLikely')}</div>
                  </div>
                </div>
              </Row>
              {showNPSSurvey.canSkip && (
                <Row marginBottom={32}>
                  <div className={styles.helper}>{t('nps.helper')}</div>
                </Row>
              )}
            </div>
            <Row marginBottom={12}>
              <Col colSpan={16}>
                <div className={tagStyles.modalFooter}>
                  {showNPSSurvey.canSkip && (
                    <button
                      id="dismiss"
                      type="button"
                      className={tagStyles.buttonSecondary}
                      onClick={onClose}
                    >
                      {t('nps.dismiss')}
                    </button>
                  )}
                  <button
                    id="continue"
                    type="button"
                    className={tagStyles.buttonPrimary}
                    onClick={() => setCurrentStep(Steps.COMMENTS)}
                    disabled={selectedRating === null}
                  >
                    {t('nps.continue')}
                  </button>
                </div>
              </Col>
            </Row>
          </Grid>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.modalWrapper}>
      <div
        className={cn(
          tagStyles.tagDs,
          tagStyles.modal,
          tagStyles.docs,
          styles.modal
        )}
      >
        {showNPSSurvey.canSkip && (
          <button
            id="close"
            type="button"
            className={tagStyles.modalCloseButton}
            onClick={onClose}
          >
            <CloseOutlinedIcon />
          </button>
        )}
        <Formik
          initialValues={{ comments: '', category: '' }}
          onSubmit={onSubmit}
          validationSchema={validationSchema(t, selectedRating)}
        >
          <Form>
            <Grid>
              <Row marginBottom={24}>
                <h4 className={tagStyles.modalHeader}>{t('nps.title')}</h4>
              </Row>
              <Row marginBottom={32}>
                <div className={tagStyles.modalBody}>
                  <Row marginBottom={12}>
                    <div className={styles.subtitle}>{t('nps.subtitle')}</div>
                  </Row>
                  <Row marginBottom={24}>
                    <div className={tagStyles.separator} />
                  </Row>
                  <Row marginBottom={24}>
                    <TextArea
                      name="comments"
                      label={`${t('nps.comments')} ${
                        selectedRating <= 6 ? '*' : ''
                      }`}
                    />
                  </Row>
                  <Row marginBottom={32}>
                    <Select
                      name="category"
                      label={`${t('nps.category')} ${
                        selectedRating <= 6 ? '*' : ''
                      }`}
                      placeholder=""
                      isSearchable={false}
                      options={Options}
                    />
                  </Row>
                </div>
                <Row>
                  {showNPSSurvey.canSkip && (
                    <Row marginBottom={32}>
                      <div className={styles.helper}>{t('nps.helper')}</div>
                    </Row>
                  )}
                </Row>
              </Row>
              <Row marginBottom={12}>
                <Col colSpan={16}>
                  <div className={tagStyles.modalFooter}>
                    <button
                      id="dismiss"
                      type="button"
                      className={tagStyles.buttonSecondary}
                      onClick={() => setCurrentStep(Steps.RATING)}
                    >
                      {t('nps.back')}
                    </button>
                    <button
                      id="submit"
                      type="submit"
                      className={tagStyles.buttonPrimary}
                    >
                      {t('nps.sendFeedback')}
                    </button>
                  </div>
                </Col>
              </Row>
            </Grid>
          </Form>
        </Formik>
      </div>
    </div>
  );
};

export default NPS;
