import cn from 'classnames';
import Card from '@common/Card';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CloseIcon from '@mui/icons-material/Close';
import tagStyles from '@common/assets/styles/main.scss';
import intlFormatDistance from 'date-fns/intlFormatDistance';
import parseISO from 'date-fns/parseISO';
import { NotificationLevel } from '@appTypes/Notification.types';
import i18n from '@i18n/i18n';

import styles from './styles.module.scss';
import { Link } from 'react-router-dom';

interface IToastComponent {
  id?: string;
  className?: string;
  title: string | React.ReactNode;
  subtitle?: string;
  createdOn?: string;
  level: string;
  showIcon?: boolean;
  actionText?: string;
  actionUrl?: string;
  onDismiss?: (id: string) => void;
}

const ToastComponent: React.FC<IToastComponent> = ({
  id,
  className,
  title,
  subtitle,
  createdOn,
  level,
  showIcon,
  actionText,
  actionUrl,
  onDismiss,
}) => {
  return (
    <Card className={cn(styles.toast, className)}>
      <div
        className={cn(styles.toastAccent, styles[NotificationLevel[level]])}
      ></div>
      {showIcon && (
        <div className={styles.toastIcon}>
          {NotificationLevel[level] === NotificationLevel.Success ||
          NotificationLevel[level] === NotificationLevel.Information ? (
            <CheckCircleOutlineIcon />
          ) : (
            <ErrorOutlineIcon />
          )}
        </div>
      )}
      <div className={styles.toastContainer}>
        <div className={styles.titleContainer}>
          <div className={styles.title}>{title}</div>
          {createdOn &&
            parseISO(createdOn) > parseISO('0001-01-01T00:00:00+00:00') && (
              <div className={styles.timeStamp}>
                {intlFormatDistance(parseISO(createdOn), new Date(), {
                  locale: i18n.language,
                })}
              </div>
            )}
        </div>
        <div className={styles.subtitleContainer}>
          <div className={styles.subtitle}>{subtitle}</div>
        </div>
        {actionUrl && (
          <div className={styles.linkContainer}>
            <Link to={actionUrl} onClick={() => onDismiss(id)}>
              <span>{actionText}</span>
            </Link>
          </div>
        )}
      </div>
      {onDismiss && (
        <div className={styles.dismissContainer}>
          <button
            className={cn(tagStyles.upperMenuIcon, styles.dismissButton)}
            onClick={() => onDismiss(id)}
          >
            <CloseIcon />
          </button>
        </div>
      )}
    </Card>
  );
};

export default ToastComponent;
