import { useEffect, useState } from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import 'material-design-icons/iconfont/material-icons.css';
import '@adeccoux/tag-ds/index.css';
import tagStyles from '@common/assets/styles/main.scss';
import Loader from '@common/Loader';
import { AppInsightsContextProvider } from '@common/utils/AppInsightsProvider';
import { AzureAppConfigProvider } from '@common/utils/AzureAppConfigProvider';
import { UILayoutContextProvider } from '@common/utils/UILayoutProvider';
import {
  InteractionRequiredAuthError,
  InteractionType,
  PublicClientApplication,
} from '@azure/msal-browser';
import {
  useMsalAuthentication,
  AuthenticatedTemplate,
  MsalProvider,
} from '@azure/msal-react';

import './i18n/i18n';
import Routes from './routes';
import styles from './App.module.scss';
import store from './redux/store';

const App = () => {
  const [client, setClient] = useState<PublicClientApplication>();
  const [authConfig, setAuthConfig] = useState(null);

  const getConfig = async () => {
    const response = await fetch('/auth.config.json');
    const authConfig = await response.json();
    setAuthConfig(authConfig);
  };

  const initialize = async () => {
    const client = new PublicClientApplication(authConfig?.msalConfig);
    await client.initialize();
    setClient(client);
  };

  useEffect(() => {
    getConfig();
  }, []);

  useEffect(() => {
    if (authConfig?.msalConfig) {
      initialize();
    }
  }, [authConfig]);

  if (!client) {
    return <Loader fullHeight />;
  }

  return (
    <MsalProvider instance={client}>
      <AuthenticatedApp authConfig={authConfig} />
    </MsalProvider>
  );
};

const AuthenticatedApp = ({ authConfig }) => {
  const request = {
    scopes: authConfig.userImpersonationScope,
  };

  const { login, error } = useMsalAuthentication(
    InteractionType.Silent,
    request
  );

  useEffect(() => {
    if (error instanceof InteractionRequiredAuthError) {
      login(InteractionType.Redirect, request);
    }
  }, [error]);

  return (
    <AuthenticatedTemplate>
      <ReduxProvider store={store}>
        <AppInsightsContextProvider>
          <AzureAppConfigProvider>
            <UILayoutContextProvider>
              <div className={tagStyles.tagDs}>
                <div className={styles.app}>
                  <Routes />
                </div>
              </div>
            </UILayoutContextProvider>
          </AzureAppConfigProvider>
        </AppInsightsContextProvider>
      </ReduxProvider>
    </AuthenticatedTemplate>
  );
};

export default App;
