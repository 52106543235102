import { createApi } from '@reduxjs/toolkit/query/react';
import * as signalR from '@microsoft/signalr';
import portalApi from '@redux/api/portalApiSlice';
import { Notification } from '@appTypes/Notification.types';
import { IncidentEventType, ReleaseEventType } from '@common/NotificationProvider';
import { authenticatedBaseQuery } from '@redux/utils/authenticatedBaseQuery';
import appInsights from '@common/utils/AppInsightsProvider';

const signalRurl = '/notificationHub';
const baseUrl = '/api/Notification';
export let hubConnection = null;

const notificationApi = createApi({
  reducerPath: 'notifications',
  baseQuery: authenticatedBaseQuery(null, baseUrl),
  endpoints: (builder) => ({
    /**
     * This method fetches notifications (both for current user
     * and for ongoing releases),
     * initially thotugh HTTP request for data in the DB
     * and then streaming updates through signalR socket connection.
     * Check https://redux-toolkit.js.org/rtk-query/usage/streaming-updates
     * for more details.
     */
    getNotifications: builder.query<Notification[], void>({
      queryFn: async (_arg, _queryApi, _extraOptions, fetchWithBaseQuery) => {
        const [myNotifications, releaseNotifications] = await Promise.all([
          fetchWithBaseQuery(`/me`),
          fetchWithBaseQuery(`/users/00000000-0000-0000-0000-000000000000`),
        ]);

        return {
          data: [
            ...(myNotifications.data as Notification[]),
            ...(releaseNotifications.data as Notification[])?.filter(
              (item) =>
                item?.data?.status === ReleaseEventType.DeploymentStarted ||
                item?.data?.status === ReleaseEventType.DeploymentScheduled ||
                item?.data?.status === ReleaseEventType.DeploymentWarning ||
                item?.data?.status === IncidentEventType.ShowIncidentNotification
            ),
          ],
        };
      },
      async onCacheEntryAdded(
        _args,
        { dispatch, updateCachedData, cacheDataLoaded, cacheEntryRemoved }
      ) {
        const token = await dispatch(
          portalApi.endpoints.getToken.initiate()
        );
        hubConnection = new signalR.HubConnectionBuilder()
          .withUrl(signalRurl, {
            accessTokenFactory: () => token?.data?.accessToken,
          })
          .configureLogging(signalR.LogLevel.Information)
          .withAutomaticReconnect([0, 0, 10000])
          .build();

        hubConnection
          .start()
          .then(() => {})
          .catch((ex) => {
            appInsights?.trackException(ex);
          });

        await cacheDataLoaded;

        try {
          const notificationEventHandler = (event: Notification) => {
            updateCachedData((cacheData) => {
              // If release completed event is recieved, remove the release started event from store.
              if (event.data?.status === ReleaseEventType.DeploymentCompleted ||
                event.data?.status === IncidentEventType.HideIncidentNotification) {
                cacheData.splice(
                  0,
                  cacheData.length,
                  ...cacheData.filter(
                    (item) =>
                      item?.data?.status === ReleaseEventType.DeploymentCompleted ||
                      item?.data?.status === IncidentEventType.HideIncidentNotification
                  )
                );
              }
              if (event.data?.status === ReleaseEventType.DeploymentScheduled) {
                cacheData.splice(
                  0,
                  cacheData.length,
                  ...cacheData.filter(
                    (item) =>
                    item?.data?.status !== ReleaseEventType.DeploymentWarning
                  )
                );
              }              
              if (event.data?.status === ReleaseEventType.DeploymentWarning ) {
                cacheData.splice(
                  0,
                  cacheData.length,
                  ...cacheData.filter(
                    (item) =>
                    item?.data?.status !== ReleaseEventType.DeploymentScheduled
                  )
                );
              }
              if (event.data?.status === ReleaseEventType.DeploymentStarted ) {
                cacheData.splice(
                  0,
                  cacheData.length,
                  ...cacheData.filter(
                    (item) =>
                    item?.data?.status !== ReleaseEventType.DeploymentScheduled &&
                    item?.data?.status !== ReleaseEventType.DeploymentWarning
                  )
                );
              }
              cacheData.push(event);
            });
          };

          hubConnection.on('ReceiveNotification', notificationEventHandler);
        } catch {}
        await cacheEntryRemoved;
        hubConnection.off('ReceiveNotification');
      },
    }),
    pushNotification: builder.mutation<void, Notification>({
      query: (body) => ({
        url: '',
        method: 'POST',
        body,
      }),
    }),
    removeMyNotification: builder.mutation<void, string>({
      query: (id) => ({
        url: `/me/${id}`,
        method: 'DELETE',
      }),
      onQueryStarted: async (id, { dispatch, queryFulfilled }) => {
        await queryFulfilled;
        dispatch(
          notificationApi.util.updateQueryData(
            'getNotifications',
            undefined,
            (cacheData) => {
              cacheData.splice(
                0,
                cacheData.length,
                ...cacheData.filter((item) => item.id !== id)
              );
            }
          )
        );
      },
    }),
  }),
});

export const removeNotification = (id) =>
  notificationApi.util.updateQueryData(
    'getNotifications',
    undefined,
    (cacheData) => {
      cacheData.splice(
        0,
        cacheData.length,
        ...cacheData.filter((item) => item.id !== id)
      );
    }
  );

export const sendPushNotification = (notification: Notification) =>
  notificationApi.util.updateQueryData(
    'getNotifications',
    undefined,
    (cacheData) => {
      cacheData.push(notification);
    }
  );

export const {
  useGetNotificationsQuery,
  usePushNotificationMutation,
  useRemoveMyNotificationMutation,
} = notificationApi;
export default notificationApi;
