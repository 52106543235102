import { useEffect, useMemo, useRef, useState } from 'react';
import cn from 'classnames';
import NotificationsIcon from '@mui/icons-material/Notifications';
import NotificationsOffOutlinedIcon from '@mui/icons-material/NotificationsOffOutlined';
import ToastComponent from '@common/ToastComponent';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import tagStyles from '@common/assets/styles/main.scss';
import {
  useGetNotificationsQuery,
  useRemoveMyNotificationMutation,
} from '@redux/api/notificationApiSlice';
import { resolveTextResource } from '@common/utils';
import Popup from '@common/Popup';
import Card from '@common/Card';

import styles from './styles.module.scss';

export enum ReleaseEventType {
  DeploymentWarning = 'DeploymentWarning',
  DeploymentScheduled = 'DeploymentScheduled',
  DeploymentStarted = 'DeploymentStarted',
  DeploymentCompleted = 'DeploymentCompleted'
}
  
export enum IncidentEventType {
  ShowIncidentNotification = 'ShowIncidentNotification',
  HideIncidentNotification = 'HideIncidentNotification'
}

const NotificationProvider = () => {
  const { t } = useTranslation(['notificationTextResources']);
  const { data: notifications } = useGetNotificationsQuery();
  const [removeNotification] = useRemoveMyNotificationMutation();
  const [open, setOpen] = useState(false);
  const mountRef = useRef(false);

  const isReleaseNotification = (notification) => {
    const status = notification?.data?.status;
    return (
      status === ReleaseEventType.DeploymentWarning ||
      status === ReleaseEventType.DeploymentScheduled ||
      status === ReleaseEventType.DeploymentStarted ||
      status === ReleaseEventType.DeploymentCompleted ||      
      status === IncidentEventType.ShowIncidentNotification ||
      status === IncidentEventType.HideIncidentNotification
    );
  }; 

  const nonReleaseNotifications = useMemo(
    () => notifications?.filter((item) => !isReleaseNotification(item)),
    [notifications]
  );
  const notificationsCount = useMemo(
    () => nonReleaseNotifications?.length,
    [nonReleaseNotifications]
  );
  const allNotificationsIds = useMemo(
      () => nonReleaseNotifications?.map((item) => item.id),
          [nonReleaseNotifications]
    )
  useEffect(() => {
    notificationsCount > 0 && mountRef.current && setOpen(true);
    notificationsCount === 0 && setOpen(false);
    mountRef.current = true;
  }, [nonReleaseNotifications]);
    
  const handleClearAll = () => {
    allNotificationsIds.forEach((notificationId) => removeNotification(notificationId))
    setOpen(false);
  }

  return (
    <div className={styles.notificationProvider}>
      <Popup
        open={open}
        trigger={
          <button
            id="notifications-button"
            className={cn(tagStyles.upperMenuIcon, styles.dropdownButton)}
          >
            {notificationsCount > 0 && (
              <div className={tagStyles.badgeSmall}>{notificationsCount}</div>
            )}
            <NotificationsIcon />
          </button>
        }
      >
        {(dismissPopup) => (
          <Card className={cn(styles.dropdown, {[styles.hasMultipleNotifications]: notificationsCount > 1 })}>
            {
            notificationsCount > 1 ? (
              <div className={styles.clearAllButtonContainer}>
                <button onClick={handleClearAll}>
                  <span className={styles.clearAllText}>Clear all notifications</span>
                  <span className={styles.clearAllIcon}><CloseIcon /></span>
                </button>
              </div>
            ) : null
            }
            {notificationsCount > 0 ? (
              nonReleaseNotifications?.map((item) => (
                <ToastComponent
                  id={item.id}
                  key={item.id}
                  title={resolveTextResource(t, item.title)}
                  subtitle={resolveTextResource(t, item.message)}
                  actionText={resolveTextResource(t,item.actionText)}
                  actionUrl={item.actionUrl}
                  createdOn={item.createdOn}
                  level={item.level}
                  showIcon
                  onDismiss={() => {
                    removeNotification(item.id);
                    if (notificationsCount === 0) {
                      setOpen(false);
                      dismissPopup();
                    }
                  }}
                />
              ))
            ) : (
              <ToastComponent
                level="Success"
                title={
                  <span className={styles.noNotificationsCard}>
                    <NotificationsOffOutlinedIcon />
                    <span className={styles.title}>
                      {t('noPendingNotifications')}
                    </span>
                  </span>
                }
              />
            )}
          </Card>
        )}
      </Popup>
    </div>
  );
};

export default NotificationProvider;
