import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import portalApi from '@redux/api/portalApiSlice';
import { Location } from '@appTypes/Azure.types';

const baseUrl = 'https://atlas.microsoft.com';

const getUserPosition = () => {
  return new Promise((resolve, reject) =>
    window.navigator.geolocation.getCurrentPosition(
      (position) => resolve(position),
      (error) => reject(error)
    )
  );
};

const azureApi = createApi({
  reducerPath: 'azure',
  baseQuery: fetchBaseQuery({ baseUrl }),
  endpoints: (builder) => ({
    getUserAddress: builder.query<any, void>({
      queryFn: async (
        _args,
        { dispatch },
        _extraOptions,
        fetchWithBaseQuery
      ) => {
        const { data: appConfig } = await dispatch(
          portalApi.endpoints.getAppConfig.initiate()
        );
        const azureMapsApiKey = appConfig.environmentConfig?.azureMapApiKey;
        try {
          const position = await getUserPosition();
          const { latitude, longitude } = (position as GeolocationPosition)
            .coords;
          const location = await fetchWithBaseQuery(
            `/search/address/reverse/json?api-version=1.0&subscription-key=${azureMapsApiKey}&language=en-US&query=${latitude},${longitude}&number=1`
          );
          return {
            data: {
              location: location.data as Location,
              coordinates: { latitude, longitude },
            },
          };
        } catch (error) {
          return { data: null };
        }
      },
    }),
    searchAddress: builder.query<
      any,
      { searchParam: string; language: string }
    >({
      queryFn: async (
        { searchParam, language },
        { dispatch },
        _extraOptions,
        fetchWithBaseQuery
      ) => {
        const { data: appConfig } = await dispatch(
          portalApi.endpoints.getAppConfig.initiate()
        );
        const azureMapsApiKey = appConfig.environmentConfig?.azureMapApiKey;
        const response = await fetchWithBaseQuery(
          `/search/address/json?&subscription-key=${azureMapsApiKey}&api-version=1.0&language=${language}&query=${searchParam}`
        );
        return { data: (response as any)?.data?.results };
      },
    }),
  }),
});

export const { useGetUserAddressQuery, useLazySearchAddressQuery } = azureApi;
export default azureApi;
