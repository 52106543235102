import {
  CampaignDetails,
  SplittedCampaign,
  Template,
} from '@appTypes/Campaign.types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ICampaignDetailsValues {
  currentCampaign: CampaignDetails;
  currentTemplate: Template;
  showUploadProgress: boolean;
  isSplitCampaign: boolean;
  splittedCampaigns: SplittedCampaign[];
  uploadStatus: any;
}

const initialState: ICampaignDetailsValues = {
  currentCampaign: null,
  currentTemplate: null,
  showUploadProgress: false,
  isSplitCampaign: false,
  splittedCampaigns: [],
  uploadStatus: {},
};

const campaignDetailsSlice = createSlice({
  name: 'campaignDetailsStore',
  initialState,
  reducers: {
    setCurrentCampaign: (state, action: PayloadAction<any>) => {
      state.currentCampaign = action.payload;
    },
    setCurrentTemplate: (state, action: PayloadAction<any>) => {
      state.currentTemplate = action.payload;
    },
    setUploadStatus: (state, action: PayloadAction<any>) => {
      const {
        CampaignId,
        CandidateCount,
        CandidateSuccess,
        CandidateFailures,
      } = action.payload;

      state.uploadStatus[CampaignId] = {
        CandidateCount,
        CandidateSuccess,
        CandidateFailures,
      };
    },
    clearUploadStatus: (state) => {
      state.uploadStatus = initialState.uploadStatus;
    },
    setShowUploadProgress: (state, action: PayloadAction<any>) => {
      state.showUploadProgress = action.payload;
    },
    setSplitCampaign: (state, action: PayloadAction<boolean>) => {
      state.isSplitCampaign = action.payload;
    },
    setSplittedCampaigns: (
      state,
      action: PayloadAction<SplittedCampaign[]>
    ) => {
      state.splittedCampaigns = action.payload;
    },
    clearCampaignDetailsState: (state) => {
      state.currentCampaign = null;
      state.currentTemplate = null;
      state.showUploadProgress = false;
      state.uploadStatus = initialState.uploadStatus;
    },
  },
});

export const {
  setCurrentCampaign,
  setCurrentTemplate,
  setUploadStatus,
  setSplitCampaign,
  setSplittedCampaigns,
  clearUploadStatus,
  clearCampaignDetailsState,
} = campaignDetailsSlice.actions;

export default campaignDetailsSlice.reducer;
