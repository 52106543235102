import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = {
  uploadStatus: {},
};

const campaignSummarySlice = createSlice({
  name: 'campaignSummary',
  initialState,
  reducers: {
    setUploadStatus: (state, action: PayloadAction<any>) => {
      const {
        CampaignId,
        CandidateCount,
        CandidateSuccess,
        CandidateFailures,
      } = action.payload;

      state.uploadStatus[CampaignId] = {
        CandidateCount,
        CandidateSuccess,
        CandidateFailures,
      };
    },
    clearCampaignSummaryState: (state) => {
      state.uploadStatus = initialState.uploadStatus;
    },
  },
});

export const { setUploadStatus, clearCampaignSummaryState } =
  campaignSummarySlice.actions;
export default campaignSummarySlice.reducer;
