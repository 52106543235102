import { createApi } from '@reduxjs/toolkit/query/react';
import { authenticatedBaseQuery } from '@redux/utils/authenticatedBaseQuery';
import {
  Candidate,
  CandidatesStatus,
  SubscriptionSummary,
} from '@appTypes/Candidate.types';

const candidateApi = createApi({
  reducerPath: 'candidate',
  baseQuery: authenticatedBaseQuery('cand'),
  tagTypes: ['candidates', 'reporting'],
  endpoints: (builder) => ({
    getCandidates: builder.query<
      { results: Candidate[]; continuationToken: string },
      {
        campaignId: string;
        filters: any;
        sort: any;
        page: number;
        continuationToken?: string;
      }
    >({
      query: ({ campaignId, filters, sort, page, continuationToken }) => {
        const queryParams = new URLSearchParams([
          ...Object.entries(filters).map((item: any[]) => [
            `${item[0]}Filter`,
            item[1],
          ]),
          ['filterSort', sort],
          ['continuationToken', continuationToken || ''],
        ]);
        return `/api/Candidate/all/campaign/${campaignId}?${queryParams}`;
      },
      providesTags: ['candidates'],
    }),
    getCandidateById: builder.query<Candidate, string>({
      query: (candidateId: string) => `/api/Candidate/${candidateId}`,
    }),
    updateCandidate: builder.mutation<Candidate, Candidate>({
      query: (candidate: Candidate) => ({
        url: `/api/Candidate`,
        method: 'PUT',
        body: candidate,
      }),
      invalidatesTags: ['candidates'],
    }),
    getSubscriptionSummaryByCampaign: builder.query<
      SubscriptionSummary,
      string
    >({
      query: (campaignId: string) =>
        `/api/Candidate/${campaignId}/subscriptionSummary`,
      providesTags: ['reporting'],
    }),
    getConversationStatusForCampaign: builder.query<CandidatesStatus, string>({
      query: (campaignId: string) =>
        `/api/Candidate/all/campaign/${campaignId}/conversationstatus`,
      providesTags: ['candidates'],
    }),
  }),
});

export const {
  useGetCandidatesQuery,
  useLazyGetCandidatesQuery,
  useGetCandidateByIdQuery,
  useLazyGetCandidateByIdQuery,
  useUpdateCandidateMutation,
  useGetSubscriptionSummaryByCampaignQuery,
  useGetConversationStatusForCampaignQuery,
} = candidateApi;
export default candidateApi;
