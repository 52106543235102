import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = {
  selectedCampaigns: [],
  selectedType: '',
  selectedStatus: 'running',
};

const reportingSlice = createSlice({
  name: 'reportingStore',
  initialState,
  reducers: {
    setSelectedCampaigns: (state, action: PayloadAction<any>) => {
      state.selectedCampaigns = action.payload;
    },
    removeSelectedCampaign: (state, action: PayloadAction<any>) => {
      state.selectedCampaigns = state.selectedCampaigns?.filter(
        (item) => item !== action.payload
      );
    },
    setSelectedType: (state, action: PayloadAction<any>) => {
      state.selectedType = action.payload;
    },
    setSelectedStatus: (state, action: PayloadAction<any>) => {
      state.selectedStatus = action.payload;
    },
    resetState: (state) => {
      state.selectedCampaigns = initialState.selectedCampaigns;
      state.selectedType = initialState.selectedType;
      state.selectedStatus = initialState.selectedStatus;
    },
  },
});

export const {
  setSelectedCampaigns,
  removeSelectedCampaign,
  setSelectedType,
  setSelectedStatus,
  resetState,
} = reportingSlice.actions;

export default reportingSlice.reducer;
