import portalApi from '@redux/api/portalApiSlice';
import { fetchBaseQuery, retry } from '@reduxjs/toolkit/dist/query';

import { getApiToken, getGraphToken } from './tokenUtils';

export const authenticatedBaseQuery = (apiName?, url?) =>
  retry(
    async (args, api, extraOptions) => {
      const { dispatch } = api;

      const { accessToken } = await getApiToken();
      const { data: appConfig } = await dispatch(
        portalApi.endpoints.getAppConfig.initiate()
      );

      const baseUrl = appConfig?.externalServices?.[apiName] || url;

      const baseQueryResult = await fetchBaseQuery({
        baseUrl,
        prepareHeaders: (headers) => {
          headers.set('Authorization', `Bearer ${accessToken}`);
          return headers;
        },
      })(args, api, extraOptions);

      if (baseQueryResult.error?.status === 403) {
        retry.fail(baseQueryResult.error);
      }

      return baseQueryResult;
    },
    { maxRetries: 2 }
  );

export const authenticatedGraphBaseQuery = (baseUrl) =>
  retry(
    async (args, api, extraOptions) => {
      const { accessToken } = await getGraphToken();

      const baseQueryResult = await fetchBaseQuery({
        baseUrl,
        prepareHeaders: (headers) => {
          headers.set('Authorization', `Bearer ${accessToken}`);
          return headers;
        },
      })(args, api, extraOptions);

      if (baseQueryResult.error?.status === 403) {
        retry.fail(baseQueryResult.error);
      }

      return baseQueryResult;
    },
    { maxRetries: 2 }
  );
