import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import SearchIcon from '@mui/icons-material/Search';
import ReactSelect, { components } from 'react-select';
import { useField } from 'formik';
import CloseIcon from '@mui/icons-material/Close';
import tagStyles from '@common/assets/styles/main.scss';
import Loader from '@components/common/Loader';

import styles from './styles.module.scss';

interface Option {
  value: string;
  label: string;
}

const Select = (props) => {
  const { t } = useTranslation('common');
  const [field, meta, helpers] = useField(props);
  const {
    options,
    onChange,
    onInputChange,
    isSearchable,
    isDismissable,
    onDismiss,
    compact,
    disabled,
  } = props;
  const hasError = meta.touched && meta.error;

  const customStyles = {
    menu: (provided) => ({
      ...provided,
      zIndex: 10000,
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#ccf2ff' : '#fff',
      fontFamily: 'Roboto, sans-serif',
      color: '#1c304b',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: '#ccf2ff',
      },
      '&:active': {
        backgroundColor: '#ccf2ff',
      },
    }),
    control: (provided) => ({
      ...provided,
      borderRadius: '8px',
      border: hasError ? '1px solid var(--error600)' : '1px solid',
      fontFamily: 'Roboto',
      fontWeight: '400',
      fontSize: '14px',
      boxShadow: 'none',
      padding: '0px',
      cursor: 'pointer',
      minHeight: compact ? '0' : provided?.minHeight,
      height: compact && '32px',
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    noOptionsMessage: (provided) => ({
      ...provided,
      fontSize: '14px',
      color: 'var(--neutral800)',
    }),
    placeholder: (provided) => ({
      ...provided,
      transform: compact && 'translateY(-5px)',
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      transform: compact && 'translate(12px, -10px)',
    }),
    input: (provided) => ({
      ...provided,
      transform: compact && 'translateY(-5px)',
    }),
    loadingIndicator: (provided) => ({
      ...provided,
      transform: compact && 'translateY(-5px)',
    }),
  };

  const value = useMemo(
    () => options?.find((option) => option.value === field.value),
    [options, field.value]
  );

  return (
    <div
      className={cn(tagStyles.tagDs, tagStyles.inputWrapper, styles.select, {
        [tagStyles.error]: hasError,
      })}
    >
      {(!isDismissable || disabled) && (
        <label className={tagStyles.caption}>{props?.label}</label>
      )}
      {isDismissable && !disabled && (
        <span className={styles.dismissContainer}>
          <label className={tagStyles.caption}>{props?.label}</label>
          {value && (
            <button
              className={cn(tagStyles.upperMenuIcon)}
              id={`clear-group-${field.name}`}
              onClick={onDismiss}
              type="button"
            >
              <CloseIcon />
            </button>
          )}
        </span>
      )}
      <ReactSelect
        {...field}
        {...props}
        inputId={props.id}
        components={{ Option, Input, DropdownIndicator }}
        className={cn({ [tagStyles.error]: hasError })}
        styles={customStyles}
        isDisabled={props.disabled}
        loadingMessage={() => <Loader />}
        noOptionsMessage={() =>
          !isSearchable ? (
            <span className={styles.helperMessage}>{t('noOptionsFound')}</span>
          ) : (
            <span className={styles.helperMessage}>
              {t('autoCompletePlaceholder')}
            </span>
          )
        }
        value={value || null}
        onChange={(option: Option) => {
          helpers.setValue(option?.value);
          onChange?.(option);
        }}
        onInputChange={(value) => {
          value && onInputChange?.(value);
        }}
      />
      {hasError && <div className={styles.error}>{meta.error}</div>}
    </div>
  );
};

const Option = (props) => {
  const optionProps = {
    ...props,
    innerProps: {
      ...props.innerProps,
      id: props.data.value,
    },
  };
  return <components.Option {...optionProps} />;
};

const Input = (props) => (
  <components.Input id={props.selectProps.id} {...props} />
);

const DropdownIndicator = (props) =>
  props?.selectProps?.isSearchable ? (
    <span className={styles.dropdownIndicator}>
      <SearchIcon />
    </span>
  ) : (
    <span className={styles.dropdownIndicator}>
      <components.DownChevron />
    </span>
  );

export default Select;
