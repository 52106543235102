import { createApi } from '@reduxjs/toolkit/query/react';
import { Event } from '@microsoft/microsoft-graph-types';
import { authenticatedGraphBaseQuery } from '@redux/utils/authenticatedBaseQuery';

const convertToBase64 = (photo) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      resolve(reader.result);
    };

    reader.onerror = reject;
    reader.readAsDataURL(photo);
  });
};

const baseUrl = 'https://graph.microsoft.com/v1.0/';

const graphApi = createApi({
  reducerPath: 'graph',
  baseQuery: authenticatedGraphBaseQuery(baseUrl),
  endpoints: (builder) => ({
    getUserCalendarEvents: builder.query<
      Event[],
      { start: string; end: string }
    >({
      queryFn: async (
        { start, end },
        _api,
        _extraOptions,
        fetchWithBaseQuery
      ) => {
        try {
          const events = await fetchWithBaseQuery(
            `/me/calendarview?top=500&StartDateTime=${start}&EndDateTime=${end}&orderby=start/dateTime ASC`
          );

          return { data: (events.data as any).value as Event[] };
        } catch (err) {
          return { data: [] };
        }
      },
    }),
    getMyProfilePhoto: builder.query<string, void>({
      queryFn: async (_args, _api, _extraOptions, fetchWithBaseQuery) => {
        try {
          const response = await fetchWithBaseQuery({
            url: `/me/photos/64x64/$value`,
            responseHandler: async (response) => await response.blob(),
          });
          const base64Photo = await convertToBase64(response.data);
          return { data: base64Photo as string };
        } catch (err) {
          return { data: null };
        }
      },
    }),
    getUserProfilePhoto: builder.query<string, string>({
      queryFn: async (
        userId: string,
        _api,
        _extraOptions,
        fetchWithBaseQuery
      ) => {
        try {
          const response = await fetchWithBaseQuery({
            url: `/users/${userId}/photos/64x64/$value`,
            responseHandler: async (response) => await response.blob(),
          });
          const base64Photo = await convertToBase64(response.data);
          return { data: base64Photo as string };
        } catch (err) {
          return { data: null };
        }
      },
    }),
  }),
});

export const {
  useGetUserCalendarEventsQuery,
  useGetMyProfilePhotoQuery,
  useGetUserProfilePhotoQuery,
} = graphApi;
export default graphApi;
