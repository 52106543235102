import { createApi } from '@reduxjs/toolkit/query/react';
import { authenticatedBaseQuery } from '@redux/utils/authenticatedBaseQuery';
import {
  Currency,
  GetAllCampaignsResponse,
  CampaignCreationResponse,
  CampaignDetails,
  Suggestions,
  MessagingTemplateContext,
  MessagingTemplateChannel,
  Template,
  Interview,
  MessagingTemplate,
  MessagingTemplateCollection,
  WaveStatus,
  CampaignCategoryName,
  SplitCampaignResponse,
} from '@appTypes/Campaign.types';

const campaignApi = createApi({
  reducerPath: 'campaign',
  baseQuery: authenticatedBaseQuery('camp'),
  tagTypes: ['AllCampiagns', 'InterviewSlots', 'MessagingTemplates'],
  endpoints: (builder) => ({
    getAllCampaigns: builder.query<GetAllCampaignsResponse, any>({
      queryFn: async (params, _queryApi, _extraOptions, fetchWithBaseQuery) => {
        const queryParams = new URLSearchParams([
          ...Object.entries(params.filters),
          ...Object.entries(params.pagination),
          ['createdByMe', String(params.createdByMe)],
          ['orderBy', params.orderBy?.toString()],
          ['isDescending', String(params.isDescending)],
        ]);
        const response = await fetchWithBaseQuery(
          `/api/campaigns?${queryParams}`
        );
        return {
          data: response.data as GetAllCampaignsResponse,
        };
      },
      providesTags: ['AllCampiagns'],
      keepUnusedDataFor: 0,
    }),
    getCampaignById: builder.query<CampaignDetails, string>({
      query: (campaignId: string) => `/api/campaign/${campaignId}`,
      keepUnusedDataFor: 0,
    }),
    updateCampaign: builder.mutation<void, CampaignDetails>({
      query: (campaign: CampaignDetails) => ({
        url: `/api/Campaign`,
        method: 'PUT',
        body: campaign,
      }),
      invalidatesTags: ['AllCampiagns'],
    }),
    updateCampaignStatus: builder.mutation<
      void,
      { campaign: CampaignDetails; status: WaveStatus }
    >({
      query: ({ campaign, status }) => ({
        url: `/api/Campaign/${campaign.id}/status/${status}`,
        method: 'PUT',
        body: campaign,
      }),
      invalidatesTags: ['AllCampiagns'],
    }),
    closeCampaignById: builder.mutation<void, string>({
      query: (campaignId: string) => ({
        url: `/api/Campaign/${campaignId}/status/Closed`,
        method: 'PUT',
        body: { id: campaignId, status: 'Closed' },
      }),
      invalidatesTags: ['AllCampiagns'],
    }),
    deleteCampaignById: builder.mutation<void, string>({
      query: (campaignId: string) => ({
        url: `/api/campaigns/${campaignId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['AllCampiagns'],
    }),
    postCampaignsWithoutCandidates: builder.mutation<
      CampaignCreationResponse,
      CampaignDetails
    >({
      query: (campaign: CampaignDetails) => ({
        url: `/api/Campaign/prepareWithoutCandidates`,
        method: 'POST',
        body: campaign,
      }),
      invalidatesTags: ['AllCampiagns'],
    }),
    copyMessagingTemplate: builder.mutation<CampaignCreationResponse, string>({
      query: (messagingTemplateId: string) => ({
        url: `/api/MessagingTemplate/copy/${messagingTemplateId}`,
        method: 'POST',
        body: {},
      }),
    }),
    copyMessagingTemplateAsInstanceForCampaign: builder.mutation<
      string,
      { campaignId: string; messagingTemplateId: string }
    >({
      query: ({ campaignId, messagingTemplateId }) => ({
        url: `/api/MessagingTemplate/copyasinstance/${messagingTemplateId}/forcampaign/${campaignId}`,
        method: 'POST',
        body: {},
      }),
    }),
    getSuggestedDatesByCountry: builder.query<Suggestions, string>({
      query: (countryCode: string) =>
        `/api/Suggestions/GetSuggestedDatesByCountry?countryCode=${countryCode}`,
    }),
    getMessagingTemplates: builder.query<
      MessagingTemplateCollection,
      { currentTemplate: Template; currentCampaign: CampaignDetails }
    >({
      queryFn: async (
        { currentTemplate, currentCampaign },
        _queryApi,
        _extraOptions,
        fetchWithBaseQuery
      ) => {
        const allTemplatesForGroup = await fetchWithBaseQuery(
          `/api/MessagingTemplate/templates/category/${currentCampaign?.category}/forgroup/${currentCampaign?.groupId}`
        );

        const allTemplates = (allTemplatesForGroup?.data as any)?.result;

        const isScheduling =
          currentCampaign.category === CampaignCategoryName.Scheduling ||
          currentCampaign.category === CampaignCategoryName.Funnel;

        const instanceMessages = currentCampaign
          ? await Promise.all([
              ...([
                ...(currentCampaign?.firstContactMessages || []),
                ...(currentCampaign?.reminderSettings || []),
                ...(isScheduling &&
                currentCampaign?.timeSlotMessages?.timeSlotCancel
                  ? currentCampaign?.timeSlotMessages?.timeSlotCancel
                  : []),
                ...(isScheduling &&
                currentCampaign?.timeSlotMessages?.timeSlotUpdate
                  ? currentCampaign?.timeSlotMessages?.timeSlotUpdate
                  : []),
              ]?.map(async (message) => {
                const template = await fetchWithBaseQuery(
                  `/api/MessagingTemplate/${message.messageId}`
                );
                return {
                  ...(template.data as MessagingTemplate),
                  ...message,
                  name: `${
                    (template.data as MessagingTemplate)?.name
                  } (Instance)`,
                };
              }) || []),
              ...(currentCampaign?.reengagements?.map(async (message) => {
                const template = await fetchWithBaseQuery(
                  `/api/MessagingTemplate/${message.id}`
                );
                return {
                  ...(template.data as MessagingTemplate),
                  ...message,
                  name: `${
                    (template.data as MessagingTemplate)?.name
                  } (Instance)`,
                };
              }) || []),
            ])
          : [];

        const templateMessagingTemplates = currentTemplate?.messagingTemplates
          ? await Promise.all(
              currentTemplate?.messagingTemplates?.map(async (message) => {
                const template = await fetchWithBaseQuery(
                  `/api/MessagingTemplate/${message.messageId}`
                );
                return {
                  ...(template.data as any),
                  context: (template.data as any)?.labels?.[0],
                };
              })
            )
          : [];

        const resultObj = {
          [MessagingTemplateChannel.Sms]: [],
          [MessagingTemplateChannel.Email]: [],
          [MessagingTemplateChannel.MobileNotification]: [],
          [MessagingTemplateChannel.WhatsApp]: [],
          [MessagingTemplateChannel.FullSMS]: [],
          [MessagingTemplateChannel.None]: [],
        };

        const categorizeByChannel = (
          acc: { [x: string]: any },
          template: { channel: string | number }
        ) => {
          acc[template.channel] = [...acc[template.channel], template];
          return acc;
        };

        return {
          data: {
            initialMessages: {
              [MessagingTemplateContext.FirstContact]: [
                ...instanceMessages,
                ...allTemplates,
              ]
                .filter(
                  (t) => t.context === MessagingTemplateContext.FirstContact
                )
                .reduce(categorizeByChannel, { ...resultObj }),
              [MessagingTemplateContext.FirstReengagement]: [
                ...instanceMessages,
                ...allTemplates,
              ]
                .filter(
                  (t) =>
                    t.context === MessagingTemplateContext.FirstReengagement
                )
                .reduce(categorizeByChannel, { ...resultObj }),
              [MessagingTemplateContext.SecondReengagement]: [
                ...instanceMessages,
                ...allTemplates,
              ]
                .filter(
                  (t) =>
                    t.context === MessagingTemplateContext.SecondReengagement
                )
                .reduce(categorizeByChannel, { ...resultObj }),
            },
            fromChatbot: {
              [MessagingTemplateContext.Cancellation]: [
                ...instanceMessages,
                ...allTemplates,
                ...templateMessagingTemplates,
                ...instanceMessages,
              ]
                .filter(
                  (t) =>
                    t.context === MessagingTemplateContext.Cancellation ||
                    t.labels[0] === 'Cancellation'
                )
                .reduce(categorizeByChannel, { ...resultObj }),
              [MessagingTemplateContext.Confirmation]: [
                ...instanceMessages,
                ...allTemplates,
                ...templateMessagingTemplates,
                ...instanceMessages,
              ]
                .filter(
                  (t) =>
                    t.context === MessagingTemplateContext.Confirmation ||
                    t.labels[0] === 'Confirmation'
                )
                .reduce(categorizeByChannel, { ...resultObj }),
            },
            afterChatbot: {
              [MessagingTemplateContext.TimeSlotCancel]: [
                ...instanceMessages,
                ...allTemplates,
              ]
                .filter(
                  (t) => t.context === MessagingTemplateContext.TimeSlotCancel
                )
                .reduce(categorizeByChannel, { ...resultObj }),
              [MessagingTemplateContext.TimeSlotUpdate]: [
                ...allTemplates,
                ...instanceMessages,
              ]
                .filter(
                  (t) => t.context === MessagingTemplateContext.TimeSlotUpdate
                )
                .reduce(categorizeByChannel, { ...resultObj }),
              [MessagingTemplateContext.Reminder]: [
                ...instanceMessages,
                ...allTemplates,
              ]
                .filter((t) => t.context === MessagingTemplateContext.Reminder)
                .reduce(categorizeByChannel, { ...resultObj }),
            },
          },
        };
      },
      providesTags: ['MessagingTemplates'],
      keepUnusedDataFor: 0,
    }),
    getMessagingTemplatebyId: builder.query<MessagingTemplate, string>({
      query: (templateId: string) => `/api/MessagingTemplate/${templateId}`,
    }),
    updateMessagingTemplate: builder.mutation<void, MessagingTemplate>({
      query: (messagingTemplate: MessagingTemplate) => ({
        url: `/api/MessagingTemplate`,
        method: 'PUT',
        body: messagingTemplate,
      }),
      invalidatesTags: ['MessagingTemplates'],
    }),
    getInterviews: builder.query<Interview[], string>({
      query: (campaignId: string) => `/api/agenda/${campaignId}/campaign/0`,
      providesTags: ['InterviewSlots'],
    }),
    createInterview: builder.mutation<void, Interview>({
      query: (interview: Interview) => ({
        url: `/api/agenda/createtimeslot`,
        method: 'POST',
        body: interview,
      }),
      invalidatesTags: ['InterviewSlots'],
    }),
    updateInterview: builder.mutation<void, Interview>({
      query: (interview: Interview) => ({
        url: `/api/agenda/updatetimeslot`,
        method: 'PUT',
        body: interview,
      }),
      invalidatesTags: ['InterviewSlots'],
    }),
    deleteInterview: builder.mutation<void, string>({
      query: (interviewId: string) => ({
        url: `/api/agenda/timeslot/${interviewId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['InterviewSlots'],
    }),
    getRemindersCount: builder.query<number, string>({
      query: (campaignId: string) =>
        `/api/Agenda/reminders/${campaignId}/count`,
    }),
    addCandidates: builder.mutation<
      void,
      {
        campaignId: string;
        file: File;
        candidateManagerType: string;
        operationType: string;
      }
    >({
      query: ({ campaignId, file, candidateManagerType, operationType }) => {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('candidateManagerType', candidateManagerType);
        formData.append('operationType', operationType);
        return {
          url: `/api/campaigns/${campaignId}/candidates`,
          method: 'POST',
          body: formData,
        };
      },
    }),
    addCandidatesAndSplitCampaign: builder.query<
      SplitCampaignResponse,
      {
        campaignId: string;
        file: File;
        candidateManagerType: string;
        operationType: string;
      }
    >({
      query: ({ campaignId, file, candidateManagerType, operationType }) => {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('candidateManagerType', candidateManagerType);
        formData.append('operationType', operationType);
        formData.append('sourceCampaignId', campaignId);
        return {
          url: `/api/campaigns/split`,
          method: 'POST',
          body: formData,
        };
      },
      keepUnusedDataFor: 0,
    }),
    storeCampaignQRCode: builder.mutation<string, any>({
      query: ({ campaignId, size, format, embedLogo }) => ({
        url: `/api/campaign/${campaignId}/qrcode?size=${size}&format=${encodeURIComponent(
          format
        )}&embedLogo=${embedLogo}`,
        method: 'POST',
      }),
    }),
    getCampaignSnapshotsByUser: builder.query<any, any>({
      queryFn: async (params, _queryApi, _extraOptions, fetchWithBaseQuery) => {
        const queryParams = new URLSearchParams([
          ...Object.entries(params.filters),
          ...Object.entries(params.pagination),
          ['count', 10000],
          ['createdByMe', String(params.createdByMe)],
          ['orderBy', params.orderBy?.toString()],
          ['isDescending', String(params.isDescending)],
        ]);
        const response = await fetchWithBaseQuery(
          `/api/reporting/campaignsnapshots-by-user?${queryParams}`
        );
        return { data: response.data as any };
      },
    }),
  }),
});

export const {
  useGetAllCampaignsQuery,
  useLazyGetAllCampaignsQuery,
  useGetCampaignByIdQuery,
  useLazyGetCampaignByIdQuery,
  useUpdateCampaignMutation,
  useUpdateCampaignStatusMutation,
  useCloseCampaignByIdMutation,
  useDeleteCampaignByIdMutation,
  usePostCampaignsWithoutCandidatesMutation,
  useCopyMessagingTemplateMutation,
  useCopyMessagingTemplateAsInstanceForCampaignMutation,
  useLazyGetSuggestedDatesByCountryQuery,
  useGetMessagingTemplatesQuery,
  useLazyGetMessagingTemplatesQuery,
  useLazyGetMessagingTemplatebyIdQuery,
  useUpdateMessagingTemplateMutation,
  useGetInterviewsQuery,
  useCreateInterviewMutation,
  useUpdateInterviewMutation,
  useDeleteInterviewMutation,
  useGetRemindersCountQuery,
  useAddCandidatesMutation,
  useLazyAddCandidatesAndSplitCampaignQuery,
  useStoreCampaignQRCodeMutation,
  useLazyGetCampaignSnapshotsByUserQuery,
} = campaignApi;
export default campaignApi;
