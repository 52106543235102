export default {
  LOGOUT: 'Logout',
  campaignsList: {
    SETTINGS: 'Settings_From_Campaign_List',
    CANDIDATES: 'Candidates_List_From_Campaign_List',
    CONVERSATION: 'Conversation_Preview_From_Campaign_List',
    REPORTS: 'Reports_From_Campaign_List',
    DUPLICATE: 'Duplicate_Campaign_From_Campaign_List',
    CLOSE: 'Close_Campaign_From_Campaign_List',
    DELETE: 'Delete_Campaign_From_Campaign_List',
  },
  createCampaign: {
    CREATE_CAMPAIGN_QUESTIONS: 'Create_Campaign_Questions',
    CREATE_CAMPAIGN_TEMPLATE: 'Create_Campaign',
    CREATE_CAMPAIGN_DESIGNER: 'Conversation_Designer',
  },
  campaignDetails: {
    STOP_CAMPAIGN: 'Stop_Campaign',
    DELETE_CAMPAIGN: 'Delete_Campaign',
    LAUNCH_CAMPAIGN_AUTOMATICALLY: 'Launch_Campaign_Automatically',
    LAUNCH_CAMPAIGN_SCHEDULED: 'Launch_Campaign_As_Planned',
    LAUNCH_CAMPAIGN_NOW: 'Launch_Campaign_Now',
  },
  users: {
    SAVE_USER_AFTER_ADD: 'Save_User_After_Add',
    SAVE_USER_AFTER_EDIT: 'Save_User_After_Edit',
    REMOVE_USER_FROM_GROUP: 'Remove_User_From_Group',
  },
};
