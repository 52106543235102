export enum Steps {
  RATING = 'rating',
  COMMENTS = 'comments',
}

export const Options = [
  {
    label: 'Design (such as Accessibility, user flows, design elements ...)',
    value: 'Design',
  },
  {
    label: 'Technical (such as the loading time, unexpected errors, bugs, ...)',
    value: 'Technical',
  },
  {
    label:
      'Date and reporting (such as campaign performance, overall metrics...)',
    value: 'DateAndReporting',
  },
  {
    label: 'Conversation (such as conversation flow and conversation creation)',
    value: 'Conversation',
  },
  { label: 'Other', value: 'Other' },
];
