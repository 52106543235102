import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CampaignDetails } from '@appTypes/Campaign.types';

interface ICampaignSettingsValues {
  formState: any;
  currentCampaign: CampaignDetails;
}

const campaignSettingsSlice = createSlice({
  name: 'campaignSettings',
  initialState: {
    formState: null,
    currentCampaign: null,
  } as ICampaignSettingsValues,
  reducers: {
    setFormState: (state, action: PayloadAction<any>) => {
      state.formState = action.payload;
    },
    clearCampaignSettingsState: (state) => {
      state.formState = null;
      state.currentCampaign = null;
    },
    setCurrentCampaign: (state, action: PayloadAction<CampaignDetails>) => {
      state.currentCampaign = action.payload;
    },
  },
});

export const { setFormState, clearCampaignSettingsState, setCurrentCampaign } =
  campaignSettingsSlice.actions;
export default campaignSettingsSlice.reducer;
