import { Component } from 'react';
import { connect } from 'react-redux';
import { onApiError } from '@redux/reducers/errorHandlerReducer';
import {
  AppInsightsContext,
  IAppInsightsContext,
} from '@common/utils/AppInsightsProvider';

class ErrorBoundary extends Component<any, any> {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static contextType: React.Context<IAppInsightsContext> = AppInsightsContext;

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error) {
    (this.context as IAppInsightsContext).trackException(error);
    if (error.name === 'ChunkLoadError') {
      window.location.reload();
    } else {
      this.props.setError({
        status: 'REACT_ERROR',
      });
    }
  }

  render() {
    const { hasError } = this.state;
    if (hasError) {
      return null;
    }
    return this.props.children;
  }
}

const mapPropsToDispatch = (dispatch) => ({
  setError: (error: string) => dispatch(onApiError(error)),
});

export default connect(null, mapPropsToDispatch)(ErrorBoundary);
