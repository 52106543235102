import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IAzureAppConfigSliceValues {
  configs: any;
  featureFlags: any;
}

const azureAppConfigSlice = createSlice({
  name: 'azureAppConfig',
  initialState: {
    configs: {},
    featureFlags: {},
  } as IAzureAppConfigSliceValues,
  reducers: {
    setConfig: (state, action: PayloadAction<{ key: string; value: any }>) => {
      const { key, value } = action.payload;
      state.configs = { ...state.configs, [key]: value };
    },
    setFeatureFlag: (
      state,
      action: PayloadAction<{ key: string; enabled: boolean }>
    ) => {
      const { key, enabled } = action.payload;
      state.featureFlags = { ...state.featureFlags, [key]: enabled };
    },
  },
});

export const { setConfig, setFeatureFlag } = azureAppConfigSlice.actions;

export default azureAppConfigSlice.reducer;
