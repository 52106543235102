import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Hotjar from '@hotjar/browser';
import {
  useGetAppConfigQuery,
  useGetTokenQuery,
} from '@redux/api/portalApiSlice';
import {
  useAuditUserLoginMutation,
  useGetPermissionsByUserIdQuery,
  useLazyGetGroupsForCurrentUserQuery,
} from '@redux/api/securityApiSlice';
import { Permission, UserPermissions } from '@appTypes/Security.types';
import { useAzureAppConfig } from '@common/utils/AzureAppConfigProvider';
import {
  setConfig,
  setFeatureFlag,
} from '@redux/reducers/azureAppConfigReducer';
import {
  configs,
  featureFlags,
} from '@common/utils/AzureConfigAndFeatureFlags';
import { RootState } from '@redux/store';

export const useInitApp = () => {
  const { data: tokenData, isLoading: isTokenLoading } = useGetTokenQuery();

  const { isLoading: isGetAppConfigLoading, data: appConfig } =
    useGetAppConfigQuery();

  const { isLoading: isGetPermissionsByUserIdLoading } =
    useGetPermissionsByUserIdQuery(tokenData?.oid, {
      skip: !tokenData?.oid,
    });

  const [
    getGroupsForCurrentUser,
    { data: userGroups, isLoading: isGetGroupsForCurrentUserLoading },
  ] = useLazyGetGroupsForCurrentUserQuery();

  const { getConfigSetting, isLoading: isGetConfigSettingLoading } =
    useAzureAppConfig();
  const dispatch = useDispatch();

  const getAzureConfigAndFeatureFlags = async () => {
    const configSettings = await Promise.all(
      configs.map((key: string) => getConfigSetting(key))
    );
    const featureFlagSettings = await Promise.all(
      featureFlags.map((key: string) => getConfigSetting(key))
    );

    configs.forEach((key, index) =>
      dispatch(
        setConfig({
          key,
          value: configSettings[index],
        })
      )
    );

    featureFlags.forEach((key, index) =>
      dispatch(
        setFeatureFlag({
          key: key.split('/')[1],
          enabled: featureFlagSettings[index]?.enabled,
        })
      )
    );
  };

  const intializeHotJar = async () => {
    const hotJarSiteId = await getConfigSetting('HotJarSiteId');
    Hotjar.init(Number(hotJarSiteId), 6);
  };

  useEffect(() => {
    intializeHotJar();
  }, []);

  const [auditUserLogin] = useAuditUserLoginMutation();
  useEffect(() => {
    if (tokenData?.oid) {
      auditUserLogin(tokenData);
    }
  }, [tokenData?.oid]);

  useEffect(() => {
    if (appConfig?.externalServices) {
      getGroupsForCurrentUser();
    }
  }, [appConfig]);

  useEffect(() => {
    if (appConfig?.environmentConfig?.azureAppConfigConnectionString) {
      getAzureConfigAndFeatureFlags();
    }
  }, [appConfig]);

  return [
    !isTokenLoading &&
      !isGetPermissionsByUserIdLoading &&
      !isGetAppConfigLoading &&
      !isGetGroupsForCurrentUserLoading &&
      !isGetConfigSettingLoading,
    userGroups && userGroups.length > 0,
  ];
};

export const useHasPermission = () => {
  const { data: tokenData } = useGetTokenQuery();
  const { data: permissions } = useGetPermissionsByUserIdQuery(tokenData?.oid, {
    skip: !tokenData?.oid,
  });

  const hasAccess = (roles, permCode) => {
    if (hasPermission(permCode)) {
      return true;
    }

    if (permissions) {
      for (const role of roles) {
        const allPermissions = permissions
          .filter((up) => up.roleName === role.name)
          .reduce(
            (prev: Permission[], curr: UserPermissions) => [
              ...prev,
              ...curr.permissions,
            ],
            []
          )
          .filter((perm) => perm.code === permCode);

        if (allPermissions.some((p) => (p.scope && role.scope) !== 0)) {
          return true;
        }
      }
    }

    return false;
  };

  const hasPermission = (permCode: string): boolean => {
    if (permissions) {
      const allPermissions = permissions
        .reduce(
          (prev: Permission[], curr: UserPermissions) => [
            ...prev,
            ...curr.permissions,
          ],
          []
        )
        .filter((perm) => perm.code === permCode);

      return allPermissions.length > 0;
    }

    return false;
  };

  return { hasAccess, hasPermission };
};

export const useConfig = (configName: string) => {
  const configs = useSelector(
    (store: RootState) => store.azureAppConfigStore
  ).configs;

  return configs[configName];
};

export const useFeatureFlag = (featureFlagName: string) => {
  const featureFlags = useSelector(
    (store: RootState) => store.azureAppConfigStore
  ).featureFlags;

  return featureFlags[featureFlagName];
};
