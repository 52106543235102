import { PublicClientApplication } from '@azure/msal-browser';
import isBefore from 'date-fns/isBefore';
import parseISO from 'date-fns/parseISO';

export const getAuthConfig = async () => {
  const response = await fetch('/auth.config.json');
  const authConfig = await response.json();
  return authConfig;
};

const setToLocalStorage = (authResult) => {
  const TOKEN_KEY = 'userinfo';
  const { accessToken, account, expiresOn } = authResult;

  let existingAuthData = null;
  try {
    existingAuthData = JSON.parse(localStorage.getItem(TOKEN_KEY));
  } catch (_err) {}

  const tokenResult = {
    accessToken,
    oid: account?.idTokenClaims?.oid,
    firstName: account?.name?.split(' ')[0],
    lastName: account?.name?.split(' ')[1],
    userDisplayName: account?.username,
    expires: new Date(expiresOn).toISOString(),
  };

  if (
    !existingAuthData ||
    isBefore(parseISO(existingAuthData?.expires), parseISO(tokenResult?.expires))
  ) {
    localStorage.setItem(TOKEN_KEY, JSON.stringify(tokenResult));
  }
};

export const getApiToken = async () => {
  const authConfig = await getAuthConfig();
  const msalInstance = new PublicClientApplication(authConfig.msalConfig);
  await msalInstance.initialize();

  const activeAccount = msalInstance.getActiveAccount();
  const accounts = msalInstance.getAllAccounts();
  const request = {
    scopes: authConfig.userImpersonationScope,
    account: activeAccount || accounts[0],
  };

  try {
    const authResult = await msalInstance.acquireTokenSilent(request);
    setToLocalStorage(authResult);
    return authResult;
  } catch (error) {
    const authResult = msalInstance.handleRedirectPromise();
    await msalInstance.acquireTokenRedirect(request);
    setToLocalStorage(authResult);
    return authResult;
  }
};

export const getGraphToken = async () => {
  const authConfig = await getAuthConfig();
  const msalInstance = new PublicClientApplication(authConfig.msalConfig);
  await msalInstance.initialize();
  
  const activeAccount = msalInstance.getActiveAccount();
  const accounts = msalInstance.getAllAccounts();
  const request = {
    scopes: authConfig.graphScope,
    account: activeAccount || accounts[0],
  };

  try {
    const authResult = await msalInstance.acquireTokenSilent(request);
    return authResult;
  } catch (error) {
    const authResult = msalInstance.handleRedirectPromise();
    await msalInstance.acquireTokenRedirect(request);
    return authResult;
  }
};
