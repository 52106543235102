import { createApi } from '@reduxjs/toolkit/query/react';
import { authenticatedBaseQuery } from '@redux/utils/authenticatedBaseQuery';
import { APP_CONFIG_KEY } from '@redux/api/portalApiSlice';
import {
  AddUserBody,
  Group,
  GroupFilterOptions,
  Permission,
  Role,
  UserPermissions,
  UserRole,
  UserSearchResult,
  UserStatistics,
} from '@appTypes/Security.types';
import { TokenResult } from '@appTypes/Portal.types';

const securityApi = createApi({
  reducerPath: 'security',
  baseQuery: authenticatedBaseQuery('secu'),
  tagTypes: ['Users', 'UsersCount'],
  endpoints: (builder) => ({
    getPermissionsByUserId: builder.query<UserPermissions[], string>({
      query: (userId) => `/api/users/${userId}/permissions`,
    }),
    getGroupsForCurrentUser: builder.query<Group[], void>({
      query: () => '/api/group/mine',
    }),
    getPermissionsForCampaignForUser: builder.query<
      Permission[],
      { userId: string; campaignId: string }
    >({
      query: ({ userId, campaignId }) =>
        `/api/users/${userId}/permissions/campaign/${campaignId}`,
    }),
    listGroupsWithFilter: builder.query<Group[], GroupFilterOptions | void>({
      query: (options: GroupFilterOptions) =>
        `/api/group/list/?${new URLSearchParams([...Object.entries(options)])}`,
    }),
    getResourcesForGroup: builder.query<
      any,
      { groupId: string; resourceType: string }
    >({
      query: ({ resourceType, groupId }) =>
        `/api/resource/${resourceType}/forgroup/${groupId}`,
      transformResponse: (response: string[], _meta, arg) => {
        const appConfig = JSON.parse(localStorage.getItem(APP_CONFIG_KEY));

        if (arg.resourceType === 'country') {
          const allCountries =
            appConfig?.referenceConfig.supportedCultures.filter((c) =>
              response.includes(c.cultureCode.toLowerCase())
            );
          const mappedCountries = allCountries.map((obj) => obj.countryCode);
          const countries = allCountries.filter(
            (v, i) => mappedCountries.indexOf(v.countryCode) === i
          );

          const languages = appConfig.referenceConfig.supportedCultures.filter(
            (c) => response.includes(c.cultureCode.toLowerCase())
          );

          return { countries, languages };
        } else if (arg.resourceType === 'brand') {
          return appConfig?.referenceConfig.brands.filter((b) =>
            response.includes(b.name)
          );
        }
      },
    }),
    getUsersFromGroup: builder.query<UserRole[], string>({
      query: (groupId: string) => `/api/users?groupIdFilter=${groupId}`,
      providesTags: ['Users'],
    }),
    getUsersByName: builder.query<UserRole[], string>({
      query: (nameFilter: string) => `/api/users?nameFilter=${nameFilter}`,
    }),
    getGroupsForUser: builder.query<Group[], string>({
      query: (userId: string) => `/api/users/${userId}/groups`,
    }),
    getRoleForUserAndGroup: builder.query<
      Group,
      { userId: string; groupId: string }
    >({
      query: ({ userId, groupId }) =>
        `/api/users/${userId}/role?groupId=${groupId}`,
    }),
    getRolesForGroup: builder.query<Role[], string>({
      query: (groupId: string) => `/api/role/group/${groupId}`,
    }),
    addUser: builder.mutation<void, AddUserBody>({
      query: (body: AddUserBody) => ({
        url: `/api/users`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Users', 'UsersCount'],
    }),
    updateUser: builder.mutation<void, AddUserBody>({
      query: (body: AddUserBody) => ({
        url: `/api/users`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['Users'],
    }),
    addUserToGroup: builder.mutation<
      any,
      { userId: string; groupId: string; roleId: string }
    >({
      query: ({ userId, groupId, roleId }) => ({
        url: `/api/group/${groupId}/attach/${userId}/withrole/${roleId}`,
        method: 'PUT',
      }),
      invalidatesTags: ['Users'],
    }),
    updateUserRole: builder.mutation<
      void,
      { userId: string; groupId: string; roleId: string }
    >({
      query: ({ userId, groupId, roleId }) => ({
        url: `/api/group/${groupId}/move/${userId}/torole/${roleId}`,
        method: 'PUT',
      }),
      invalidatesTags: ['Users'],
    }),
    removeUserFromGroup: builder.mutation<
      void,
      { userId: string; groupId: string }
    >({
      query: ({ userId, groupId }) => ({
        url: `/api/group/${groupId}/detach/${userId}`,
        method: 'PUT',
      }),
      invalidatesTags: ['Users'],
    }),
    searchForOrganizationUsers: builder.query<
      { results: UserSearchResult[]; skipToken: string },
      string
    >({
      query: (userSearchPattern) => `/api/users/directory/${userSearchPattern}`,
    }),
    getGroupDetails: builder.query<Group, string>({
      query: (groupId: string) => `/api/group/${groupId}`,
    }),
    getUserStatisticsByGroupId: builder.query<UserStatistics, string>({
      query: (groupId: string) => `/api/group/${groupId}/users/statistics`,
      providesTags: ['UsersCount'],
    }),
    auditUserLogin: builder.mutation<void, TokenResult>({
      query: (user: TokenResult) => ({
        url: `/api/users/${user.oid}/login/audit`,
        method: 'PUT',
        body: {
          email: user?.userDisplayName,
          firstName: user?.firstName,
          lastName: user?.lastName,
          operationTime: new Date().toISOString(),
          success: true,
          errorMessage: null,
        },
      }),
    }),
  }),
});

export const {
  useGetGroupsForCurrentUserQuery,
  useLazyGetGroupsForCurrentUserQuery,
  useGetPermissionsByUserIdQuery,
  useLazyGetPermissionsByUserIdQuery,
  useGetPermissionsForCampaignForUserQuery,
  useListGroupsWithFilterQuery,
  useLazyListGroupsWithFilterQuery,
  useGetResourcesForGroupQuery,
  useLazyGetUsersFromGroupQuery,
  useLazyGetUsersByNameQuery,
  useLazyGetGroupsForUserQuery,
  useLazyGetRoleForUserAndGroupQuery,
  useGetRolesForGroupQuery,
  useAddUserMutation,
  useUpdateUserMutation,
  useAddUserToGroupMutation,
  useUpdateUserRoleMutation,
  useRemoveUserFromGroupMutation,
  useLazySearchForOrganizationUsersQuery,
  useGetGroupDetailsQuery,
  useGetUserStatisticsByGroupIdQuery,
  useAuditUserLoginMutation,
} = securityApi;
export default securityApi;
