import { useLayoutEffect, useRef } from 'react';
import { useField } from 'formik';
import tagStyles from '@common/assets/styles/main.scss';
import cn from 'classnames';

import styles from './styles.module.scss';

const TextArea = (props) => {
  const [field, meta, helpers] = useField(props);
  const ref = useRef(null);

  const hasError = meta.touched && meta.error;

  useLayoutEffect(() => {
    ref.current.style.height = 'inherit';
    ref.current.style.height = `${Math.max(ref.current.scrollHeight, 70)}px`;
  }, [field.value]);

  return (
    <div
      className={cn(tagStyles.tagDs, tagStyles.inputWrapper, styles.input, {
        [tagStyles.error]: hasError,
      })}
    >
      <label className={tagStyles.caption}>
        {props?.label}
        <textarea
          className={cn({ [tagStyles.error]: hasError })}
          ref={ref}
          {...field}
          {...props}
          onChange={(e) => {
            helpers.setValue(e.target.value);
            props.onChange?.(e);
          }}
        />
      </label>
      {hasError && <div className={styles.error}>{meta.error}</div>}
    </div>
  );
};

export default TextArea;
