import { MessageTemplateUsage, ValidationResult } from '@appTypes/CxBot.types';
import { authenticatedBaseQuery } from '@redux/utils/authenticatedBaseQuery';
import { createApi } from '@reduxjs/toolkit/query/react';

const cxBotApi = createApi({
  reducerPath: 'ontology',
  baseQuery: authenticatedBaseQuery('cxbot'),
  endpoints: (builder) => ({
    retrieveMessagingTemplates: builder.mutation<
      MessageTemplateUsage[],
      string
    >({
      query: (content: string) => ({
        url: `/api/RetrieveMessagingTemplates`,
        method: 'POST',
        body: content,
      }),
    }),
    getBotToken: builder.query<any, void>({
      query: () => `/api/tokens/direct-line`,
      keepUnusedDataFor: 0,
    }),
    sendFeedback: builder.mutation<void, any>({
      query: (body) => ({
        url: `/api/ConversationRating/feedback`,
        method: 'POST',
        body,
      }),
    }),
    validateTemplateJson: builder.mutation<ValidationResult, string>({
      query: (data: string) => ({
        url: `/api/JsonValidation`,
        method: 'POST',
        body: data,
      }),
    }),
    importConversationLanguages: builder.mutation<
      ValidationResult,
      { templateId: string; csvFile: string }
    >({
      query: ({ templateId, csvFile }) => ({
        url: `/api/Template/ImportTextResources/${templateId}`,
        method: 'POST',
        body: csvFile,
      }),
    }),
  }),
});

export const {
  useRetrieveMessagingTemplatesMutation,
  useGetBotTokenQuery,
  useLazyGetBotTokenQuery,
  useSendFeedbackMutation,
  useValidateTemplateJsonMutation,
  useImportConversationLanguagesMutation,
} = cxBotApi;
export default cxBotApi;
