import React, { useContext, useState } from 'react';
import { AppConfigurationClient } from '@azure/app-configuration';
import { useLazyGetAppConfigQuery } from '@redux/api/portalApiSlice';

interface IContext {
  getConfigSetting: (key: string) => Promise<any>;
  isLoading: boolean;
}

export const AzureAppConfigContext = React.createContext<IContext | undefined>(
  undefined
);

export const AzureAppConfigProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [getAppConfig] = useLazyGetAppConfigQuery();
  const [isLoading, setLoading] = useState(false);

  const getClient = async () => {
    const { data: appConfig } = await getAppConfig();
    const client = new AppConfigurationClient(
      appConfig?.environmentConfig?.azureAppConfigConnectionString
    );

    return client;
  };

  const getConfigSetting = async (key: string) => {
    setLoading(true);
    const client = await getClient();
    const setting = await client?.getConfigurationSetting(
      { key },
      {
        requestOptions: { customHeaders: { 'cache-control': 'no-cache' } },
        onlyIfChanged: true,
      }
    );
    setLoading(false);
    try {
      return JSON.parse(setting.value);
    } catch (err) {}
  };

  return (
    <AzureAppConfigContext.Provider value={{ getConfigSetting, isLoading }}>
      {children}
    </AzureAppConfigContext.Provider>
  );
};

export const useAzureAppConfig = () => {
  const azureAppConfigContext = useContext(AzureAppConfigContext);
  if (azureAppConfigContext === undefined) {
    throw new Error(
      'AzureAppConfigContext must be used inside AzureAppConfigProvider'
    );
  }

  return azureAppConfigContext;
};
