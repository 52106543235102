import React, { useEffect, useRef, useState } from 'react';
import { usePopper } from 'react-popper';

interface IPopup {
  trigger: React.ReactElement;
  children: React.ReactElement | ((dismiss: (e?: Event) => void) => void);
  open?: boolean;
}

const Popup: React.FC<IPopup> = ({ trigger, children, open, ...rest }) => {
  const referenceElement = useRef(null);
  const popperElement = useRef(null);
  const arrowElement = useRef(null);
  const [isOpen, setOpen] = useState(open);

  const { styles, attributes, update } = usePopper(
    referenceElement.current,
    popperElement.current,
    {
      placement: 'bottom-start',
      modifiers: [
        { name: 'arrow', options: { element: arrowElement.current } },
        {
          name: 'flip',
          enabled: true,
        },
        {
          name: 'preventOverflow',
          options: {
            altAxis: true,
            altBoundary: true,
          },
        },
      ],
    }
  );

  const handleClickOutside = (e) => {
    if (
      !popperElement?.current?.contains(e.target as Node) &&
      !referenceElement?.current?.contains(e.target as Node)
    ) {
      setOpen(false);
      update?.();
    }
  };

  useEffect(() => {
    open !== undefined && setOpen(open);
    update?.();
  }, [open, children]);

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const TriggerElement = React.cloneElement(trigger, {
    ...trigger.props,
    onClick: (e) => {
      trigger.props?.onClick?.(e);
      setOpen(!isOpen);
      update?.();
    },
    ref: referenceElement,
  });

  return (
    <>
      {TriggerElement}
      <div
        ref={popperElement}
        style={{ ...styles.popper, zIndex: '10000' }}
        {...attributes.popper}
        {...rest}
      >
        {isOpen &&
          ((typeof children === 'function'
            ? children((e) => {
                e?.stopPropagation();
                setOpen(false);
                update?.();
              })
            : children) as any)}
        <div ref={arrowElement} style={styles.arrow} data-popper-arrow />
      </div>
    </>
  );
};

export default Popup;
