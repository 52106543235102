import { TFunction } from 'i18next';
import jschardet from 'jschardet';
import { marked } from 'marked';
import { mangle } from 'marked-mangle';
import { gfmHeadingId } from 'marked-gfm-heading-id';

export const resolveTextResource = (t: TFunction, text: string) => {
  if (text) {
    const matchResult = text.match(/t\((\w+),?(.+)?\)/); //NOSONAR <secure regular expression>
    if (matchResult && matchResult.length > 0) {
      let resolvedText = t(matchResult[1]);
      let parameters = [];
      if (matchResult[2]) {
        parameters = matchResult[2]?.split(',');
      }
      let index = 0;
      for (const param of parameters) {
        while (true) {
          const paramSanitize = param.replace(/\{\d+\}/g, '');
          const replaced = resolvedText.replace(
            '{' + index + '}',
            paramSanitize
          );
          if (replaced === resolvedText) break;
          resolvedText = replaced;
        }
        index++;
      }
      return resolvedText;
    }
  }
  return text;
};

export const detectEncoding = (file: File) => {
  return new Promise(async (resolve: (value: string) => void, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      const fileInfo = jschardet.detect(reader.result?.toString());
      resolve(fileInfo?.encoding);
    };

    reader.onerror = reject;
    reader.readAsBinaryString(file);
  });
};

export const readFile = (file: File) => {
  return new Promise(async (resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      resolve(reader.result);
    };

    reader.onerror = reject;
    reader.readAsText(file);
  });
};

export const convertUrlToFile = async (url: string, fileName: string) => {
  try {
    const response = await fetch(url);
    const blob = await response.blob();
    const file = new File([blob], fileName);
    return file;
  } catch (err) {}
  return null;
};

export const capitalizeFirstLetter = (text: string) =>
  text.substring(0, 1).toUpperCase().concat(text.substring(1));

const renderer = {
  link: (href: string, _title: string, text: string) =>
    `<a href=${href} target="_blank" rel="noreferrer noopener">${text}</a>
      <span class="material-icons" 
      style="color: var(--secondary500);transform: translate(0px, 4px);
      font-size: 18px;">open_in_new</span>`,

  strong: (text: string) => `<strong>${text}</strong>`,
};

export const parseMarkdownText = (text: string) => {
  marked.use(mangle());
  marked.use(gfmHeadingId({}));
  marked.use({ renderer });
  return marked.parse(text);
};
