import { authenticatedBaseQuery } from '@redux/utils/authenticatedBaseQuery';
import { createApi } from '@reduxjs/toolkit/query/react';

const integrationApi = createApi({
    reducerPath: 'intg',
    baseQuery: authenticatedBaseQuery('intg'),
    endpoints: (builder) => ({
        getSkillsByKeyword: builder.query<
            any,
            { searchParam: string; language: string }
        >({
            query: ({ searchParam, language }) => ({
                url: `/api/ontologies/skills/search`,
                method: 'POST',
                body: {
                    cultureCode: language,
                    SkillName: searchParam,
                },
            }),
            transformResponse: (response: any[]) => {
                return response?.map(
                    (item) =>
                        Object.keys(item) && {
                            value: Object.keys(item)[0],
                            label: item[Object.keys(item)[0]]?.description,
                        }
                );
            },
        }),
        getJobsByKeyword: builder.query<
            any,
            { searchParam: string; language: string }
        >({
            query: ({ searchParam, language }) => ({
                url: `/api/ontologies/professions/search`,
                method: 'POST',
                body: {
                    cultureCode: language,
                    professionName: searchParam,
                },
            }),
            transformResponse: (response: any[]) => {
                return response?.map(
                    (item) =>
                        Object.keys(item) && {
                            value: Object.keys(item)[0],
                            label: item[Object.keys(item)[0]]?.description,
                        }
                );
            },
        }),
        getJobTitlesByKeyword: builder.query<
            any,
            { searchParam: string; language: string }
        >({
            query: ({ searchParam, language }) => ({
                url: `/api/ontologies/job-titles/search`,
                method: 'POST',
                body: {
                    cultureCode: language,
                    jobTitleName: searchParam,
                },
            }),
            transformResponse: (response: any[]) => {
                return response?.map(
                    (item) =>
                        Object.keys(item) && {
                            value: Object.keys(item)[0],
                            label: item[Object.keys(item)[0]]?.description,
                        }
                );
            },
        }),
        getLanguageSkillsByKeyword: builder.query<
            any,
            { searchParam: string; language: string }
        >({
            query: ({ searchParam, language }) => ({
                url: `/api/ontologies/language-skills/search`,
                method: 'POST',
                body: {
                    cultureCode: language,
                    languageSkillName: searchParam,
                },
            }),
            transformResponse: (response: any[]) => {
                return response?.map(
                    (item) =>
                        Object.keys(item) && {
                            value: Object.keys(item)[0],
                            label: item[Object.keys(item)[0]]?.description,
                        }
                );
            },
        }),
    }),
});

export const {
    useLazyGetSkillsByKeywordQuery,
    useLazyGetJobsByKeywordQuery,
    useLazyGetJobTitlesByKeywordQuery,
    useLazyGetLanguageSkillsByKeywordQuery,
} = integrationApi;
export default integrationApi;
