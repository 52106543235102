import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useGetNotificationsQuery } from '@redux/api/notificationApiSlice';
import { ReleaseEventType } from '@common/NotificationProvider';
import { NotificationLevel } from '@appTypes/Notification.types';
import { useGetPermissionsByUserIdQuery } from '@redux/api/securityApiSlice';
import { useGetTokenQuery } from '@redux/api/portalApiSlice';
import styles from './styles.module.scss';
import { resolveTextResource } from '@common/utils';

const ReleaseWarning = () => {
  const { t } = useTranslation('notificationTextResources');
  const { data: notifications } = useGetNotificationsQuery();
  const { data: tokenData } = useGetTokenQuery();
  const { data: permissions } = useGetPermissionsByUserIdQuery(tokenData?.oid, {
    skip: !tokenData?.oid,
  });
  const releaseScheduledNotification = notifications?.find(
    (item) => item?.data?.status === ReleaseEventType.DeploymentScheduled || item?.data?.status === ReleaseEventType.DeploymentWarning
  );
  const releaseNotification = notifications?.find(
    (item) => item?.data?.status === ReleaseEventType.DeploymentStarted 
  );
  const isGlobalAdmin = permissions.find((permission) => permission.roleName === 'Global Admin');
    
  if (releaseNotification && isGlobalAdmin) {
    const releaseNotificationText = resolveTextResource(t, releaseNotification.title);
    const longWarningPhrases = releaseNotificationText.split('.');
    const textLimitIdx = longWarningPhrases.length > 5 
      ? releaseNotificationText.indexOf(longWarningPhrases[3])
      : releaseNotificationText.indexOf(longWarningPhrases[2]);
    const shortNotificationText = releaseNotificationText.slice(0, textLimitIdx);
    return (
      <div
        className={cn(
          styles.releaseSchedule,
          styles[NotificationLevel.Critical]
        )}
      >
        <div className={styles.releaseScheduleText}>
          {`USERS WARNING: ${shortNotificationText}`}
        </div>
      </div>
    );
  }

  if (releaseScheduledNotification) {
    return (
      <div
        className={cn(
          styles.releaseSchedule,
          styles[NotificationLevel.Critical]
        )}
      >
        <div className={styles.releaseScheduleText}>
          {resolveTextResource(t, releaseScheduledNotification.title)}
        </div>
      </div>
    );
  }
  return null;
};

export default ReleaseWarning;
