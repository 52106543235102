import { combineReducers } from 'redux';
import portalApi from '@redux/api/portalApiSlice';
import notificationApi from '@redux/api/notificationApiSlice';
import campaignApi from '@redux/api/campaignApiSlice';
import candidateApi from '@redux/api/candidateApiSlice';
import communicationApi from '@redux/api/communicationApiSlice';
import integrationApi from '@redux/api/integrationApiSlice';
import securityApi from '@redux/api/securityApiSlice';
import conversationApi from '@redux/api/conversationApiSlice';
import cxBotApi from '@redux/api/cxBotApiSlice';
import graphApi from '@redux/api/graphApiSlice';
import azureApi from '@redux/api/azureApiSlice';
import textApi from '@redux/api/textApiSlice';

import mainReducer from '@redux/reducers/mainReducer';
import errorReducer from '@redux/reducers/errorHandlerReducer';
import campaignSettingsReducer from '@redux/reducers/createCampaign/campaignSettingsReducer';
import questionListReducer from '@redux/reducers/createCampaign/questionListReducer';
import templateSelectionReducer from '@redux/reducers/createCampaign/templateSelectionReducer';
import conversationStructureReducer from '@redux/reducers/createCampaign/conversationStructureReducer';
import outreachMethodReducer from '@redux/reducers/createCampaign/outreachMethodReducer';
import campaignSummaryReducer from '@redux/reducers/createCampaign/campaignSummaryReducer';
import azureAppConfigReducer from '@redux/reducers/azureAppConfigReducer';
import campaignDetailsReducer from '@redux/reducers/campaignDetailsReducer';
import templatesListReducer from '@redux/reducers/templatesListReducer';
import reportingReducer from '@redux/reducers/reportingReducer';

const rootReducer = combineReducers({
  mainStore: mainReducer,
  azureAppConfigStore: azureAppConfigReducer,
  campaignSettingsStore: campaignSettingsReducer,
  questionListStore: questionListReducer,
  templateSelectionStore: templateSelectionReducer,
  conversationStructureStore: conversationStructureReducer,
  outreachMethodStore: outreachMethodReducer,
  campaignSummaryStore: campaignSummaryReducer,
  campaignDetailsStore: campaignDetailsReducer,
  templatesListStore: templatesListReducer,
  reportingStore: reportingReducer,
  errors: errorReducer,
  [portalApi.reducerPath]: portalApi.reducer,
  [notificationApi.reducerPath]: notificationApi.reducer,
  [campaignApi.reducerPath]: campaignApi.reducer,
  [candidateApi.reducerPath]: candidateApi.reducer,
  [communicationApi.reducerPath]: communicationApi.reducer,
  [integrationApi.reducerPath]: integrationApi.reducer,
  [conversationApi.reducerPath]: conversationApi.reducer,
  [securityApi.reducerPath]: securityApi.reducer,
  [cxBotApi.reducerPath]: cxBotApi.reducer,
  [textApi.reducerPath]: textApi.reducer,
  [graphApi.reducerPath]: graphApi.reducer,
  [azureApi.reducerPath]: azureApi.reducer,
});

export default rootReducer;
