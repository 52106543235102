import cn from 'classnames';
import { Loader as TagLoader } from '@adeccoux/tag-ds';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.scss';

interface ILoaderProps {
  fullHeight?: boolean;
  [rest: string]: any;
}

const Loader: React.FC<ILoaderProps> = ({ fullHeight, ...rest }) => {
  const { t } = useTranslation('common');
  return (
    <div
      className={cn(styles.loader, { [styles.verticallyCentered]: fullHeight })}
    >
      <div className="tag-ds">
        <TagLoader spinner title={t('pleaseWait')} {...rest} />
      </div>
    </div>
  );
};

export default Loader;
