import { createApi } from '@reduxjs/toolkit/query/react';
import { authenticatedBaseQuery } from '@redux/utils/authenticatedBaseQuery';
import {
  CampaignCategoryName,
  CampaignType,
  ConversationType,
  MessagingTemplate,
  NewCampaignType,
  Template,
  TemplateState,
  TemplateType,
} from '@appTypes/Campaign.types';
import {
  ActiveCampaigns,
  ActiveCampaignsByBrand,
  AverageMessageCount,
  CampaignDetailsResponse,
  ClientSummary,
  ConversationCreationResponse,
  Engagements,
  GlobalReportingResponse,
  Last24HrsReport,
  ReportingConversationAbandonned,
  ReportingConversationQuestions,
  ReportingConversationStartDate,
  ReportingQuestionStep,
  FunnelReportingSummary,
  ResourcesResult,
  ShowSurveyReponse,
  TemplateQuestionCategory,
  TemplateUserAccess,
  TemplateWithLanguages,
  ReportingSummary,
  TemplateParameter,
  TemplateQuestion,
} from '@appTypes/Conversation.types';
import { CandidateManagerType } from '@appTypes/Candidate.types';

const conversationApi = createApi({
  reducerPath: 'conversation',
  baseQuery: authenticatedBaseQuery('conv'),
  tagTypes: ['ConversationTemplate', 'Reporting', 'Templates'],
  endpoints: (builder) => ({
    getTemplateForCampaign: builder.query<
      Template,
      { campaignId: string; templateId: string }
    >({
      query: ({ campaignId, templateId }) =>
        `/api/Template/latestInstance/${campaignId}/${templateId}`,
      providesTags: ['ConversationTemplate'],
    }),
    getLatestTemplateById: builder.query<Template, string>({
      query: (templateId: string) => `/api/Template/latest/${templateId}`,
      providesTags: ['ConversationTemplate'],
    }),
    getTemplateById: builder.query<Template, string>({
      query: (templateId: string) => `/api/Template/${templateId}`,
      transformResponse: (response: {
        result: Template[];
        resultCount: number;
      }) => response?.result?.[0],
      providesTags: ['ConversationTemplate'],
    }),
    copyAsTemplateInstance: builder.mutation<
      ConversationCreationResponse,
      { templateId: string; version: number; campaignHasCbeCandidates: boolean }
    >({
      query: ({ templateId, version, campaignHasCbeCandidates }) => ({
        url: `/api/template/copyasinstance/${templateId}/version/${version}?campaignHasCbeCandidates=${campaignHasCbeCandidates}`,
        method: 'POST',
        body: {},
      }),
    }),
    postTemplate: builder.mutation<
      ConversationCreationResponse,
      { template: Template; groupId?: string }
    >({
      query: ({ template, groupId }) => ({
        url: groupId ? `/api/Template?groupId=${groupId}` : `/api/Template`,
        method: 'POST',
        body: template,
      }),
      invalidatesTags: ['Templates'],
    }),
    updateTemplate: builder.mutation<void, Template>({
      query: (template: Template) => ({
        url: `/api/Template`,
        method: 'PUT',
        body: template,
      }),
      invalidatesTags: ['ConversationTemplate'],
    }),
    getAllCategoriesAndQuestions: builder.query<
      {
        categories: TemplateQuestionCategory;
        parts: TemplateQuestion[];
        questionsWithCategories: any;
      },
      {
        language: string;
        isFileUploadEnabled: boolean;
        candidatesManagerType: CandidateManagerType;
      }
    >({
      query: ({ language }) =>
        `/api/TemplateQuestion/getAllCategoriesAndQuestions?languageCode=${language}`,
      transformResponse: (
        { categories, parts },
        _meta,
        { isFileUploadEnabled, candidatesManagerType }
      ) => {
        let mappedParts = parts.map((part) => ({
          ...part,
          originalType: part.type,
          choices: [],
        }));

        if (candidatesManagerType === CandidateManagerType.CareerAssistant) {
          mappedParts = mappedParts.filter((part) => !part.isMapped);
        }

        if (isFileUploadEnabled) {
          const questionsWithCategories = categories.map((category) => ({
            ...category,
            questions: mappedParts.filter(
              (part) => part.categoryId === category.id
            ),
          }));

          return { categories, parts: mappedParts, questionsWithCategories };
        }

        const questionsWithCategories = categories
          .filter((category) => category.id !== 'uploadDocuments')
          .map((category) => ({
            ...category,
            questions: mappedParts.filter(
              (part) => part.categoryId === category.id
            ),
          }));
        return { categories, parts: mappedParts, questionsWithCategories };
      },
    }),
    getAllCategoriesAndTemplates: builder.query<
      any,
      {
        groupId: string;
        userId: string;
        language: string;
        newCampaignType: NewCampaignType;
        conversationTypes: ConversationType[];
      }
    >({
      query: ({ groupId }) =>
        `/api/Template/type/Template/header/status/None/forgroup/${groupId}`,
      transformResponse: (
        { result },
        _meta,
        { userId, language, newCampaignType, conversationTypes }
      ) => {
        const templatesWithCategories: any = [];
        const templates =
          newCampaignType === NewCampaignType.Inbound
            ? result?.filter(
                (item) =>
                  item.languages?.includes(language) &&
                  item.category === CampaignType.Open
              )
            : result?.filter(
                (item) =>
                  item.languages?.includes(language) &&
                  item.category !== CampaignType.Open
              );

        conversationTypes.forEach((type) => {
          templatesWithCategories.push({
            id: type.name,
            title: type.displayName,
            templates: templates.filter(
              (template) =>
                template.isVisible && type.name === template.conversationType
            ),
          });
        });

        templatesWithCategories.push({
          id: 'myTemplates',
          title: 'My Templates',
          templates: templates.filter(
            (template) => template.createdBy === userId
          ),
        });

        templatesWithCategories.push({
          id: 'allTemplates',
          title: 'All Templates',
          templates,
        });

        return templatesWithCategories;
      },
    }),
    getSummaryByCampaignId: builder.query<ReportingSummary, string>({
      query: (campaignId: string) => `/api/Reporting/${campaignId}/Summary`,
      providesTags: ['Reporting'],
    }),
    getFunnelReportingByCampaignId: builder.query<
      FunnelReportingSummary,
      string
    >({
      query: (campaignId: string) =>
        `/api/Reporting/${campaignId}/FunnelReporting`,
      providesTags: ['Reporting'],
    }),
    createTemplateFromQuestions: builder.mutation<
      any,
      {
        name: string;
        questionList: any;
        language: string;
        category: CampaignCategoryName;
        isScheduling: boolean;
        saveAsTemplate: boolean;
        description?: string;
        conversationType?: string;
        brand?: string;
        parameters?: Record<string, TemplateParameter>;
      }
    >({
      queryFn: async (
        {
          questionList,
          language,
          category,
          isScheduling,
          saveAsTemplate,
          name,
          description,
          conversationType,
          brand,
          parameters,
        },
        _api,
        _options,
        fetchWithBaseQuery
      ) => {
        const body = {
          name,
          description: description || 'TEMPORARY TEMPLATE FOR SELECT QUESTIONS',
          category,
          brand,
          conversationType: conversationType || undefined,
          type: conversationType ? 'Template' : 'TemplateFromQuestionSelection',
          languageCode: language,
          isScheduling,
          saveAsTemplate,
          questions: [],
          parameters,
        };
        questionList.forEach((item) => {
          if (
            item.id === 'initialMessage' ||
            item.id === 'endingMessage' ||
            item.id === 'failureMessage' ||
            item.id === 'askConversationRating'
          ) {
            body[item.id] = item.value;
          } else {
            body.questions.push(item);
          }
        });

        const { data } = await fetchWithBaseQuery({
          url: `/api/templates/create-from-questions`,
          method: 'POST',
          body,
        });

        return { data };
      },
      invalidatesTags: ['ConversationTemplate'],
    }),
    addMessagingTemplateToTemplate: builder.mutation<
      Template,
      {
        campaignId: string;
        templateId: string;
        messagingTemplate: MessagingTemplate;
      }
    >({
      query: ({ campaignId, templateId, messagingTemplate }) => ({
        url: `/api/Template/${campaignId}/${templateId}/messagingtemplate`,
        body: messagingTemplate,
        method: 'PUT',
      }),
      invalidatesTags: ['ConversationTemplate'],
    }),
    getResources: builder.query<ResourcesResult, void>({
      query: () => `/api/TemplateQuestion/getResources`,
    }),
    updateTemplateInstance: builder.mutation<
      void,
      { campaignId: string; template: Template }
    >({
      query: ({ campaignId, template }) => ({
        url: `/api/Template/${campaignId}/UpdateTemplateInstance`,
        method: 'PUT',
        body: template,
      }),
    }),
    getClientSummaryByCampaign: builder.query<ClientSummary, string>({
      query: (campaignId: string) => `/api/Reporting/${campaignId}/ClientInfo`,
      providesTags: ['Reporting'],
    }),
    getCandidateEngagementsByCampaign: builder.query<Engagements[], string>({
      query: (campaignId: string) => `/api/Reporting/${campaignId}/Engagements`,
      providesTags: ['Reporting'],
    }),
    getAverageMessageCount: builder.query<AverageMessageCount, string>({
      query: (campaignId: string) =>
        `/api/Reporting/${campaignId}/averageMessageCount`,
      providesTags: ['Reporting'],
    }),
    getAverageConversationLength: builder.query<number, string>({
      query: (campaignId: string) =>
        `/api/Reporting/${campaignId}/averageConversationLength`,
      providesTags: ['Reporting'],
    }),
    getConversationStartDateByCampaign: builder.query<
      ReportingConversationStartDate[],
      string
    >({
      query: (campaignId: string) =>
        `/api/Reporting/${campaignId}/ConversationStartDate`,
      transformResponse: (response: any) => response.result,
      providesTags: ['Reporting'],
    }),
    getConversationQuestionsByCampaign: builder.query<
      ReportingConversationQuestions[],
      string
    >({
      query: (campaignId: string) =>
        `/api/Reporting/${campaignId}/ConversationQuestions`,
      transformResponse: (response: any) => response.result,
      providesTags: ['Reporting'],
    }),
    getAnswersOfQuestionStep: builder.query<ReportingQuestionStep[], string>({
      query: (campaignId: string) =>
        `/api/Reporting/${campaignId}/questionStep`,
      transformResponse: (response: any) => response.result,
      providesTags: ['Reporting'],
    }),
    getConversationAbandonnedByCampaign: builder.query<
      ReportingConversationAbandonned[],
      string
    >({
      query: (campaignId: string) =>
        `/api/Reporting/${campaignId}/ConversationAbandonned`,
      transformResponse: (response: any) => response.result,
      providesTags: ['Reporting'],
    }),
    getTemplateHeadersByTypeStatusCategoryAndGroup: builder.query<
      TemplateWithLanguages[],
      {
        templateType: TemplateType;
        templateState: TemplateState;
        templateCategory: string;
        groupId: string;
      }
    >({
      query: ({ templateType, templateState, templateCategory, groupId }) =>
        `/api/Template/type/${templateType}/header/status/${templateState}/category/${templateCategory}/forgroup/${groupId}`,
      transformResponse: (data: any) => data?.result,
    }),
    getTemplateHeadersByTypeStatusAndCategory: builder.query<
      TemplateUserAccess[],
      {
        templateType: TemplateType;
        templateState: TemplateState;
        templateCategory: string;
      }
    >({
      query: ({ templateType, templateState, templateCategory }) =>
        `/api/Template/type/${templateType}/header/status/${templateState}/category/${templateCategory}`,
      transformResponse: (data: any) => data?.result?.map((item) => item?.data),
    }),
    getAllTemplates: builder.query<any, any>({
      queryFn: async (params, _queryApi, _extraOptions, fetchWithBaseQuery) => {
        const queryParams = new URLSearchParams([
          ...Object.entries(params?.filters),
          ...Object.entries(params?.pagination),
          ['orderBy', params.orderBy?.toString()],
          ['isDescending', String(params?.isDescending)],
        ]);
        const response = await fetchWithBaseQuery(
          `/api/templates?${queryParams}`
        );
        return {
          data: {
            count: (response.data as any).resultCount,
            templates: (response.data as any)?.result?.map((item) => ({
              ...item.data,
              group: item.groupPath,
              owner: item.owner,
            })),
          },
        };
      },
      providesTags: ['Templates'],
    }),
    deleteTemplateById: builder.mutation<
      void,
      { templateId: string; templateVersion: string }
    >({
      query: ({ templateId, templateVersion }) => ({
        url: `/api/Template/${templateId}/${templateVersion}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Templates'],
    }),
    updateDataExportSetting: builder.mutation<
      void,
      { campaignId: string; templateId: string; dataExportSetting: any }
    >({
      query: ({ campaignId, templateId, dataExportSetting }) => ({
        url: `/api/campaigns/${campaignId}/template-instance/${templateId}/data-export-settings`,
        method: 'PUT',
        body: dataExportSetting,
      }),
    }),
    getCampaignTrackingList: builder.query<CampaignDetailsResponse[], string[]>(
      {
        query: (campaignIds: string[]) => ({
          url: `/api/Reporting/CampaignTrackingList`,
          method: 'POST',
          body: campaignIds,
        }),
      }
    ),
    getGlobalReporting: builder.mutation<
      GlobalReportingResponse,
      {
        campaignType: NewCampaignType;
        fromDate?: Date;
        toDate?: Date;
        campaignPurpose?: string;
      }
    >({
      query: ({ campaignType, fromDate, toDate, campaignPurpose }) => {
        const queryParams = new URLSearchParams([
          ['campaignType', campaignType],
        ]);
        fromDate && queryParams.set('fromDate', fromDate.toString());
        toDate && queryParams.set('toDate', toDate.toString());
        campaignPurpose && queryParams.set('campaignPurpose', campaignPurpose);
        return {
          url: `/api/Reporting/global-reporting?${queryParams}`,
          method: 'GET',
        };
      },
    }),
    getlast24hrsData: builder.query<Last24HrsReport, void>({
      query: () => `/api/Reporting/GlobalReporting24h`,
    }),
    getActiveCampaigns: builder.query<ActiveCampaigns[], void>({
      query: () => `/api/Reporting/active-campaigns`,
    }),
    getActiveCampaignsByBrand: builder.query<ActiveCampaignsByBrand[], void>({
      query: () => `/api/Reporting/active-campaigns-by-brand`,
    }),
    showNPSSurvey: builder.query<ShowSurveyReponse, string>({
      query: (userId: string) => `/api/Reporting/${userId}/showsurvey`,
    }),
    sendRecruiterNPS: builder.mutation<void, any>({
      query: ({ userId, body }) => ({
        url: `/api/Reporting/${userId}/recruiter-nps`,
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const removeFromCampaignTrackingList = (campaignId: string, args) =>
  conversationApi.util.updateQueryData(
    'getCampaignTrackingList',
    args,
    (cacheData) => {
      const index = cacheData?.findIndex(
        (item) => item.campaignId === campaignId
      );
      cacheData?.splice(index, 1);
    }
  );

export const {
  useGetTemplateForCampaignQuery,
  useGetTemplateByIdQuery,
  useLazyGetTemplateByIdQuery,
  useLazyGetTemplateForCampaignQuery,
  useGetLatestTemplateByIdQuery,
  useLazyGetLatestTemplateByIdQuery,
  useCopyAsTemplateInstanceMutation,
  usePostTemplateMutation,
  useUpdateTemplateMutation,
  useGetAllCategoriesAndQuestionsQuery,
  useGetAllCategoriesAndTemplatesQuery,
  useGetSummaryByCampaignIdQuery,
  useLazyGetSummaryByCampaignIdQuery,
  useGetFunnelReportingByCampaignIdQuery,
  useLazyGetFunnelReportingByCampaignIdQuery,
  useCreateTemplateFromQuestionsMutation,
  useAddMessagingTemplateToTemplateMutation,
  useGetResourcesQuery,
  useUpdateTemplateInstanceMutation,
  useGetClientSummaryByCampaignQuery,
  useGetCandidateEngagementsByCampaignQuery,
  useGetAverageMessageCountQuery,
  useGetAverageConversationLengthQuery,
  useGetConversationStartDateByCampaignQuery,
  useGetConversationQuestionsByCampaignQuery,
  useGetAnswersOfQuestionStepQuery,
  useGetConversationAbandonnedByCampaignQuery,
  useLazyGetTemplateHeadersByTypeStatusCategoryAndGroupQuery,
  useLazyGetTemplateHeadersByTypeStatusAndCategoryQuery,
  useGetAllTemplatesQuery,
  useLazyGetAllTemplatesQuery,
  useDeleteTemplateByIdMutation,
  useUpdateDataExportSettingMutation,
  useLazyGetCampaignTrackingListQuery,
  useGetGlobalReportingMutation,
  useGetlast24hrsDataQuery,
  useGetActiveCampaignsQuery,
  useGetActiveCampaignsByBrandQuery,
  useShowNPSSurveyQuery,
  useSendRecruiterNPSMutation,
} = conversationApi;
export default conversationApi;
