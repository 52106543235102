import { TFunction } from 'i18next';
import * as Yup from 'yup';

export const validationSchema = (t: TFunction, selectedRating: number) =>
  Yup.object().shape({
    comments: Yup.string().test(
      'validateComments',
      t('campaign:validationErrors.required'),
      (value) =>
        selectedRating <= 6 ? Boolean(value || value?.trim() === '') : true
    ),
    category: Yup.string().test(
      'validateCategory',
      t('campaign:validationErrors.required'),
      (value) =>
        selectedRating <= 6 ? Boolean(value || value?.trim() === '') : true
    ),
  });
