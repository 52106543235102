import cn from 'classnames';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  removeNotification,
  useGetNotificationsQuery,
} from '@redux/api/notificationApiSlice';
import CloseIcon from '@mui/icons-material/Close';
import { RootState, useAppDispatch } from '@redux/store';
import { IncidentEventType } from '@common/NotificationProvider';
import { resolveTextResource } from '@common/utils';
import { NotificationLevel, SeverityLevel } from '@appTypes/Notification.types';
import tagStyles from '@common/assets/styles/main.scss';

import styles from './styles.module.scss';

const MessageBar = () => {
  const { t } = useTranslation('notificationTextResources');
  const { apiError } = useSelector((state: RootState) => state.errors);
  const { data: notifications } = useGetNotificationsQuery();
  const dispatch = useAppDispatch();

  const incidentNotification = notifications?.find(
    (item) =>
      item?.data?.status === IncidentEventType.ShowIncidentNotification ||
      item?.data?.status === IncidentEventType.HideIncidentNotification
  );

  const [isOpen, setOpen] = useState(false);
  const errorType = incidentNotification?.data?.hasOwnProperty('severityLevel')
    ? getSeverityLevel()
    : NotificationLevel.Critical;

  useEffect(() => {
    setOpen(apiError || incidentNotification);
  }, [apiError, notifications]);

  const onClose = () => {
    incidentNotification &&
      dispatch(removeNotification(incidentNotification.id));
    setOpen(false);
  };

  return isOpen ? (
    <div className={cn(styles.messageBar, styles[errorType])}>
      <div className={styles.messageBarText}>
        {apiError
          ? t('apiErrorMsg', { code: apiError?.status })
          : resolveTextResource(t, incidentNotification.title)}
      </div>
      {errorType !== NotificationLevel.Critical && (
        <button
          className={cn(
            tagStyles.upperMenuIcon,
            styles.closeButton,
            styles[errorType]
          )}
          onClick={onClose}
        >
          <CloseIcon />
        </button>
      )}
    </div>
  ) : null;

  function getSeverityLevel() {
    switch (incidentNotification?.data?.severityLevel) {
      case SeverityLevel.Critical:
        return NotificationLevel.Critical;
      case SeverityLevel.Warning:
        return NotificationLevel.Warning;
      case SeverityLevel.Information:
        return NotificationLevel.Information;
      case SeverityLevel.Success:
        return NotificationLevel.Success;
      default:
        return NotificationLevel.Critical;
    }
  }
};

export default MessageBar;
