import React, { useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { ReleaseEventType, IncidentEventType } from '@common/NotificationProvider';
import { useGetNotificationsQuery } from '@redux/api/notificationApiSlice';
import { RootState } from '@redux/store';

interface IContext {
  isMessageBarOpen: boolean;
  isSidebarOpen: boolean;
  setSidebarOpen: (isOpen: boolean) => void;
}

export const UILayoutContext = React.createContext<IContext | undefined>(
  undefined
);

export const UILayoutContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [isSidebarOpen, setSidebarOpen] = useState(false);

  const { apiError } = useSelector((state: RootState) => state.errors);
  const { data: notifications } = useGetNotificationsQuery();

  const releaseNotification = notifications?.find(
    (item) =>
      item?.data?.status === ReleaseEventType.DeploymentWarning ||
      item?.data?.status === ReleaseEventType.DeploymentScheduled ||
      item?.data?.status === ReleaseEventType.DeploymentCompleted ||
      item?.data?.status === ReleaseEventType.DeploymentStarted ||      
      item?.data?.status === IncidentEventType.ShowIncidentNotification ||
      item?.data?.status === IncidentEventType.HideIncidentNotification
  );

  const isMessageBarOpen = apiError || releaseNotification;

  return (
    <UILayoutContext.Provider
      value={{
        isMessageBarOpen,
        isSidebarOpen,
        setSidebarOpen,
      }}
    >
      {children}
    </UILayoutContext.Provider>
  );
};

export const useUILayout = () => {
  const uiLayoutContext = useContext(UILayoutContext);
  if (uiLayoutContext === undefined) {
    throw new Error(
      'UILayoutContext must be used inside UILayoutContextProvider'
    );
  }

  return uiLayoutContext;
};
