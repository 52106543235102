import { SplittedCampaign } from '@appTypes/Campaign.types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const outreachMethodSlice = createSlice({
  name: 'outreachMethod',
  initialState: {
    formState: null,
    isSplitCampaign: false,
    splittedCampaigns: [] as SplittedCampaign[],
  },
  reducers: {
    setFormState: (state, action: PayloadAction<any>) => {
      state.formState = action.payload;
    },
    clearOutreachMethodState: (state) => {
      state.formState = null;
      state.isSplitCampaign = false;
      state.splittedCampaigns = []
    },
    setSplitCampaign: (state, action: PayloadAction<boolean>) => {
      state.isSplitCampaign = action.payload;
    },
    setSplittedCampaigns: (
      state,
      action: PayloadAction<SplittedCampaign[]>
    ) => {
      state.splittedCampaigns = action.payload;
    },
  },
});

export const {
  setFormState,
  clearOutreachMethodState,
  setSplitCampaign,
  setSplittedCampaigns,
} = outreachMethodSlice.actions;
export default outreachMethodSlice.reducer;
