import { TFunction } from 'i18next';

export interface Candidate {
  id: string;
  campaignId: string;
  title: string;
  firstName: string;
  lastName: string;
  email: string;
  conversationStatus: ConversationStatus;
  availabilityDate: Date;
  conversationStartDate: Date;
  conversationEndDate: Date;
  conversationLastUpdate: Date;
  termsAndCondAcceptedDate: Date;
  phoneNumbers: PhoneNumbers;
  physicalAddress: Address;
  lastPosition: Position;
  availability: Availability;
  skills: string[];
  created: Date;
  updated: Date;
  receiveSmsMessages: string[];
  language: string;
  pushNotificationReachable: boolean;
  askStopSms: boolean;
  askUnstopSms: boolean;
  askStopEmail: boolean;
  askUnstopEmail: boolean;
  isAnonymised: boolean;
  props: Record<string, any>;
  shortenerCodes: ShortenerCode[];
  scoreSentiment: number;
  dataPrivacy: boolean;
  termsOfUse: boolean;
  isInboundApply: boolean;
  secondaryOriginId: string;
  candidateOriginId: string;
}

export interface PhoneNumbers {
  home: string;
  cellPhone: string;
}

export interface Address {
  address1: string;
  address2: string;
  zipCode: string;
  city: string;
  state: string;
  country: string;
}

export interface Position {
  company: string;
  role: string[];
  from: Date;
  to: Date;
  duration: string;
  city: string;
  country: string;
}

export interface Availability {
  isAvailable: boolean;
  timestamp: Date;
}

export interface ShortenerCode {
  code: string;
  context: string;
  channel: string;
}

export interface SubscriptionSummary {
  unsubscribeSms: number;
  subscribeSms: number;
  unsubscribeEmail: number;
  subscribeEmail: number;
}

export interface CandidatesStatus {
  none: number;
  inProgress: number;
  success: number;
  fail: number;
}

export enum ConversationStatus {
  None = 'None',
  InProgress = 'InProgress',
  Success = 'Success',
  Fail = 'Fail',
}

export enum CandidateManagerType {
  CareerAssistant = 'CareerAssistant',
  AamBe = 'AamBe',
  Hybrid = 'Hybrid',
}

export enum OperationType {
  Upload = 'Upload',
  Reupload = 'Reupload',
}

export const candidateManagerTypeOptions = (t: TFunction) => [
  {
    label: t('candidateManagerTypeOptions.CareerAssistant'),
    value: CandidateManagerType.CareerAssistant,
  },
  {
    label: t('candidateManagerTypeOptions.AamBe'),
    value: CandidateManagerType.AamBe,
  },
];

export const candidateManagerTypeOptionsWithHybrid = (t: TFunction) => [
  {
    label: t('candidateManagerTypeOptions.CareerAssistant'),
    value: CandidateManagerType.CareerAssistant,
  },
  {
    label: t('candidateManagerTypeOptions.AamBe'),
    value: CandidateManagerType.AamBe,
  },
  {
    label: t('candidateManagerTypeOptions.Hybrid'),
    value: CandidateManagerType.Hybrid,
  },
];
