import {
  configureStore,
  isRejectedWithValue,
  Middleware,
  MiddlewareAPI,
} from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';

import rootReducer from '@redux/rootReducer';
import portalApi from '@redux/api/portalApiSlice';
import notificationApi from '@redux/api/notificationApiSlice';
import campaignApi from '@redux/api/campaignApiSlice';
import candidateApi from '@redux/api/candidateApiSlice';
import communicationApi from '@redux/api/communicationApiSlice';
import conversationApi from '@redux/api/conversationApiSlice';
import integrationApi from '@redux/api/integrationApiSlice';
import securityApi from '@redux/api/securityApiSlice';
import cxBotApi from '@redux/api/cxBotApiSlice';
import textApi from '@redux/api/textApiSlice';
import graphApi from '@redux/api/graphApiSlice';
import azureApi from '@redux/api/azureApiSlice';
import { onApiError } from '@redux/reducers/errorHandlerReducer';

const isDevServer = process.env.NODE_ENV === 'development';

export const rtkQueryErrorLogger: Middleware =
  (api: MiddlewareAPI) => (next) => (action) => {
    if (
      isRejectedWithValue(action) &&
      ![409].includes(action.payload?.status)
    ) {
      api.dispatch(onApiError(action.payload));
    }

    return next(action);
  };

const store = configureStore({
  reducer: rootReducer,
  devTools: isDevServer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(
      rtkQueryErrorLogger,
      portalApi.middleware,
      notificationApi.middleware,
      campaignApi.middleware,
      candidateApi.middleware,
      conversationApi.middleware,
      communicationApi.middleware,
      integrationApi.middleware,
      securityApi.middleware,
      cxBotApi.middleware,
      graphApi.middleware,
      azureApi.middleware,
      textApi.middleware
    ),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch: () => AppDispatch = useDispatch;
export default store;
