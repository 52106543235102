export enum NotificationLevel {
  Success = 'success',
  Information = 'info',
  Warning = 'warning',
  Critical = 'error',
}

export enum SeverityLevel {
  Success = 'Success',
  Information = 'Information',
  Warning = 'Warning',
  Critical = 'Critical',
}

export interface Notification {
  toastId?: string;
  id: string;
  userId?: string;
  level: NotificationLevel;
  title: string;
  message?: string;
  actionUrl?: string;
  actionText?: string;
  data?: Record<string, any>;
  dataType?: string;
  createdOn?: string;
}

export interface NotificationEventHandler {
    event: string;
    handler: (any) => Notification
}