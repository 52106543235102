import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { useGetAppConfigQuery } from '@redux/api/portalApiSlice';
import { createBrowserHistory } from 'history';
import React, { useContext } from 'react';

export interface IAppInsightsContext {
  trackPageView: (name: string) => void;
  trackEvent: (name: string) => void;
  trackException: (error: Error) => void;
}

let appInsights: ApplicationInsights = null;

export const AppInsightsContext = React.createContext<IAppInsightsContext | undefined>(
  undefined
);

export const AppInsightsContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { data: appConfig } = useGetAppConfigQuery();
  const history = createBrowserHistory();
  const reactPlugin = new ReactPlugin();
  const instrumentationKey = appConfig?.environmentConfig?.instrumentationKey;

  if (instrumentationKey) {
    appInsights = new ApplicationInsights({
      config: {
        instrumentationKey,
        extensions: [reactPlugin as any],
        extensionConfig: {
          [reactPlugin.identifier]: {
            history,
          },
        },
      },
    });
    appInsights.loadAppInsights();
    appInsights.trackPageView();
  }

  return (
    <AppInsightsContext.Provider
      value={{
        trackPageView: (name: string) => appInsights?.trackPageView({ name }),
        trackEvent: (name: string) => appInsights?.trackEvent({ name }),
        trackException: (error: Error) =>
          appInsights?.trackException({ error }),
      }}
    >
      {children}
    </AppInsightsContext.Provider>
  );
};

export const useAppInsights = () => {
  const appInsightsContext = useContext(AppInsightsContext);
  if (appInsightsContext === undefined) {
    throw new Error(
      'AppInsightsContext must be used inside AppInsightsContextProvider'
    );
  }

  return appInsightsContext;
};

export default appInsights;
