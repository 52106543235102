import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const templatesListSlice = createSlice({
  name: 'templatesListStore',
  initialState: {
    templatesListFilters: {},
    templatesListSort: { isDescending: true, column: 'created' },
  } as any,
  reducers: {
    setTemplatesListFilters: (state, action: PayloadAction<any>) => {
      state.templatesListFilters = action.payload;
    },
    setTemplatesListSort: (state, action: PayloadAction<any>) => {
      state.templatesListSort = action.payload;
    },
  },
});

export const { setTemplatesListFilters, setTemplatesListSort } =
  templatesListSlice.actions;

export default templatesListSlice.reducer;
