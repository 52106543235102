import { createApi } from '@reduxjs/toolkit/query/react';
import { AppConfig, EntityMetadata, TokenResult } from '@appTypes/Portal.types';
import { getApiToken, getGraphToken } from '@redux/utils/tokenUtils';
import isBefore from 'date-fns/isBefore';
import parseISO from 'date-fns/parseISO';
import { hubConnection } from './notificationApiSlice';
import { authenticatedBaseQuery } from '@redux/utils/authenticatedBaseQuery';

export const TOKEN_KEY = 'userinfo';
export const APP_CONFIG_KEY = 'appconfig';
const baseUrl = '';

const portalApi = createApi({
  reducerPath: 'appConfig',
  baseQuery: authenticatedBaseQuery(null, baseUrl),
  tagTypes: ['AppToken'],
  endpoints: (builder) => ({
    getAppConfig: builder.query<AppConfig, void>({
      /* Setting appconfig into localstorage
      becuase legacy components reads it from LocalStorage.
      In future, this will be removed and replaced by
      RTK Query caching mechanism.  */
      async queryFn(_arg, _queryApi, _extraOptions) {
        const { accessToken } = await getApiToken();

        const response = await fetch('/api/app/config', {
          headers: { Authorization: `Bearer ${accessToken}` },
        });
        const appConfig = await response.json();
        localStorage.setItem(APP_CONFIG_KEY, JSON.stringify(appConfig));
        return { data: appConfig as AppConfig };
      },
    }),
    getToken: builder.query<TokenResult, void>({
      queryFn: async (_arg, _queryApi, _extraOptions) => {
        const tokenData = localStorage.getItem(TOKEN_KEY);

        if (
          !tokenData ||
          (tokenData &&
            isBefore(parseISO(JSON.parse(tokenData).expires), new Date()))
        ) {
          const { accessToken, account, expiresOn } = await getApiToken();
          const { accessToken: graphAccessToken } = await getGraphToken();

          const getuserNameResponse = await fetch(
            `https://graph.microsoft.com/v1.0/me`,
            {
              headers: { Authorization: `Bearer ${graphAccessToken}` },
            }
          );
          const userName = await getuserNameResponse.json();

          const tokenResult = {
            accessToken,
            oid: account?.idTokenClaims?.oid,
            firstName: userName?.givenName,
            lastName: userName?.surname,
            userDisplayName: account?.username,
            expires: new Date(expiresOn).toISOString(),
          };

          if (accessToken) {
            localStorage.removeItem(TOKEN_KEY);
          } else {
            localStorage.setItem(TOKEN_KEY, JSON.stringify(tokenResult));
          }

          return { data: tokenResult as TokenResult };
        }

        return { data: JSON.parse(tokenData) as TokenResult };
      },
      providesTags: ['AppToken'],
    }),
    registerSignalRGroup: builder.mutation<void, string>({
      queryFn: async (campaignId: string) => {
        const { accessToken } = await getApiToken();

        await fetch(`/api/app/registerGroup/${campaignId}`, {
          body: `"${hubConnection?.connectionId}"`,
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'content-type': 'application/json',
          },
          method: 'PUT',
        });

        return { data: null };
      },
    }),
    unRegisterSignalRGroup: builder.mutation<void, string>({
      queryFn: async (campaignId: string) => {
        const { accessToken } = await getApiToken();

        await fetch(`/api/app/unregisterGroup/${campaignId}`, {
          body: `"${hubConnection?.connectionId}"`,
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'content-type': 'application/json',
          },
          method: 'PUT',
        });

        return { data: null };
      },
    }),
    getOutreachAutocompleteSuggestions: builder.query<EntityMetadata[], void>({
      query: () => `/api/metadata`,
      transformResponse: (metadata: any) => {
        const baseOptions = metadata
          .map(
            (item) =>
              (item.name === 'Campaign' || item.name === 'Candidate') && item
          )
          .filter((item) => item);

        const options = baseOptions.map((option) => {
          option.properties?.forEach((property) => {
            const subProperty = metadata.find(
              (item) => item.name === property.type
            );
            if (subProperty) {
              property.properties
                ? property.properties.push(...subProperty.properties)
                : (property.properties = subProperty.properties);
            }
          });

          return option;
        });
        return options;
      },
    }),
  }),
});

export const {
  useGetAppConfigQuery,
  useLazyGetAppConfigQuery,
  useGetTokenQuery,
  useRegisterSignalRGroupMutation,
  useUnRegisterSignalRGroupMutation,
  useGetOutreachAutocompleteSuggestionsQuery,
  useLazyGetOutreachAutocompleteSuggestionsQuery,
} = portalApi;
export default portalApi;
